import React from 'react'
import './footer.scss'
import star from '../../Static/footerimg.svg'

import lik from '../../Static/lik.svg'
import bb1 from '../../Static/bb1.svg'
import bb2 from '../../Static/bb2.svg'
import bb3 from '../../Static/bb3.svg'
import bb4 from '../../Static/bb4.svg'
import bb5 from '../../Static/bb5.svg'
import bb6 from '../../Static/bb6.svg'

export default function index() {
    return (
        <div className='footerpage'>
            <div className='footerinner'>

                <div className='insidefooter'>
                    <h1
                        style={{
                            fontSize: "20px",
                            fontWeight: 700
                        }}
                    >About</h1>
                    <h1>Our Story</h1>
                    <h1>Our Founders</h1>

                    <h1>Executives</h1>
                    <h1>Investors</h1>
                    <h1>Advisors</h1>
                    <h1>Footprint</h1>

                    <h1>Roadmap</h1>
                    <h1>Philanthropy</h1>

                </div>


                <div className='insidefooter'>
                    <h1
                        style={{
                            fontSize: "20px",
                            fontWeight: 700
                        }}
                    >Brands</h1>
                    <h1>Enterprise</h1>
                    <h1>Consumer</h1>
                    <h1>Offline</h1>
                </div>

                <div className='insidefooter'>
                    <h1
                        style={{
                            fontSize: "20px",
                            fontWeight: 700
                        }}
                    >Programs</h1>
                    <h1>Supernova</h1>
                    <h1>Global Warming</h1>
                    <h1>Gates Of India</h1>
                </div>

                <div className='insidefooter'>
                    <h1
                        style={{
                            fontSize: "20px",
                            fontWeight: 700
                        }}
                    >Contact Us</h1>
                    <h1>Email</h1>
                    <h1>Social Media</h1>
                    <h1>Newsletter</h1>
                    <h1>Offices</h1>
                    <h1>Newsletter</h1>
                </div>

                <div className="ImageSec">
                    <img src={star} />
                    <div className='innerclass'>
                        <img src={lik} />
                        <img src={bb1} />
                        <img src={bb2} />
                        <img src={bb3} />
                        <img src={bb4} />
                        <img src={bb5} />
                        <img src={bb6} />
                    </div>
                </div>
                <p>Copyright © 2023 Starfish Accelerator Partners. All Rights Reserved</p>
            </div>


        </div>
    )
}
