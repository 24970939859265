import React, { useEffect, useState } from "react";
import "./tacker.scss";

import { useHistory } from "react-router-dom";
import logo from "../../Static/logodesk.svg";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import CountUp from "react-countup";
import Usage from "./Usage";
import Economy from "./Economy";
import Vault from "./Vault";
export default function Index() {
  const navigate = useHistory();
  const [menuName, setMenuName] = useState("Global");
  const [tabName, settabName] = useState("Usage");


  const [AllApps, setAllApps] = useState([]);
  const [countdata, setcountdata] = useState({});
  const [titleloader, settitleloader] = useState(true);
  const [loader, setloader] = useState(true);
  const [tableName, settableName] = useState("Applications");
  const [foundercount, setfoundercount] = useState("");
  const [Allfounders, setAllfounders] = useState([]);
  const [loaderf, setloaderf] = useState(true);
  const [AllBussiness, setAllBussiness] = useState([]);
  const [Bloader, setBloader] = useState(true);

  const tabs = [
    {
      name: "Usage",
    },
    {
      name: "Economy",
    },
    {
      name: "Vault",
    },
    {
      name: "Capital",
    },
    {
      name: "Viralverse",
    },
  ];
  const year = ["All Time"];
  const menu = ["Global"];
  const menus = [
    "Canada",
    "United States",
    "India",
    "United Kingdom",
    "Australia",
  ];
  const mS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  // useEffect(() => {
  //   appcall();
  //   founder();
  //   appbussiness();
  //   axios
  //     .get(
  //       `https://comms.globalxchange.io/coin/vault/service/commissions/total/values/get`
  //     )
  //     .then((res) => {
  //       setcountdata(res.data);
  //       settitleloader(false);
  //     });
  // }, []);

  const founder = () => {
    axios
      .get(
        `https://comms.globalxchange.io/gxb/app/gxlive/user/operator/get?show_apps=true`
      )
      .then((res) => {
        setAllfounders(res.data.operators);
        setfoundercount(res.data);
        setloaderf(false);
      });
  };

  const appcall = () => {
    axios.get(`https://comms.globalxchange.io/gxb/apps/get`).then((res) => {
      setAllApps(res.data.apps);
      setloader(false);
    });
  };

  const appbussiness = () => {
    axios.get(`https://teller2.apimachine.com/admin/allBankers`).then((res) => {
      setAllBussiness(res.data.data);
      setBloader(false);
    });
  };

  const formatNumber = (inputNumber) => {
    let formetedNumber = Number(inputNumber)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    let splitArray = formetedNumber.split(".");
    if (splitArray.length > 1) {
      formetedNumber = splitArray[0];
    }
    return formetedNumber;
  };

  const headertable = () => {
    switch (tableName) {
      case "Businesses":
        return (
          <div className="headerSectionTable">
            <div
              style={{
                width: "38%",
                paddingLeft: "3%",
              }}
            >
              Name
            </div>
            <div
              style={{
                width: "27%",
              }}
            >
              Joined{" "}
            </div>
            <div
              style={{
                width: "25%",
              }}
            >
              Products
            </div>
            <div
              style={{
                width: "10%",
              }}
            >
              Followers
            </div>
          </div>
        );

      case "Founders":
        return (
          <div className="headerSectionTable">
            <div
              style={{
                width: "38%",
                paddingLeft: "3%",
              }}
            >
              Name
            </div>
            <div
              style={{
                width: "27%",
              }}
            >
              Country{" "}
            </div>
            <div
              style={{
                width: "25%",
              }}
            >
              Apps
            </div>
            <div
              style={{
                width: "10%",
              }}
            >
              Users
            </div>
          </div>
        );

      case "Applications":
        return (
          <div className="headerSectionTable">
            <div
              style={{
                width: "46%",
                paddingLeft: "3%",
              }}
            >
              Name
            </div>
            <div
              style={{
                width: "43%",
              }}
            >
              Started On{" "}
            </div>
            <div
              style={{
                width: "11%",
              }}
            >
              Users
            </div>
          </div>
        );
    }
  };

  const swtchData = () => {
    switch (tableName) {
      case "Businesses":
        return (
          <>
            {Bloader ? (
              <>
                {[1, 2, 2, 2, 2, 2, 2, 2].map((item) => {
                  return (
                    <div className="contain-bottom">
                      <Skeleton style={{ height: "30px", width: "60px" }} />
                      <Skeleton style={{ height: "30px", width: "80px" }} />

                      <Skeleton style={{ height: "30px", width: "80px" }} />

                      <Skeleton style={{ height: "30px", width: "60px" }} />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {AllBussiness?.map((item, i) => {
                  console.log(
                    " item?.date?.split('-')?.[0]",
                    item?.date?.split(",")?.[0]?.split("/")?.[0]
                  );

                  const timestamp = Date.now(); // This would be the timestamp you want to format

                  let pop = new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }).format(timestamp);
                  return (
                    <div className="contain-bottom">
                      <h1
                        style={{
                          width: "3%",
                        }}
                      >
                        {i + 1}
                      </h1>
                      <h1
                        style={{
                          width: "35%",
                        }}
                      >
                        <img
                          style={{
                            height: "27.48px",
                            marginRight: "10px",
                          }}
                          src={item?.profilePicURL}
                        />
                        {item?._displayName}
                      </h1>
                      <h1
                        style={{
                          width: "27%",
                        }}
                      >
                        {mS[pop?.split(",")?.[0]?.split("/")?.[0] - 1]}{" "}
                        {ordinal_suffix_of(
                          pop?.split(",")?.[0]?.split("/")?.[1]
                        )}{" "}
                        {pop?.split(",")?.[0]?.split("/")?.[2]}
                      </h1>

                      <h1
                        style={{
                          width: "25%",
                        }}
                      >
                        {formatNumber(item?.productsCount)}
                      </h1>

                      <h1
                        style={{
                          width: "10%",
                        }}
                      >
                        {formatNumber(item?.bankerFollowersCount)}
                      </h1>
                    </div>
                  );
                })}
              </>
            )}
          </>
        );
      case "Founders":
        return (
          <>
            {loaderf ? (
              <>
                {[1, 2, 2, 2, 2, 2, 2, 2].map((item) => {
                  return (
                    <div className="contain-bottom">
                      <Skeleton style={{ height: "30px", width: "60px" }} />
                      <Skeleton style={{ height: "30px", width: "80px" }} />

                      <Skeleton style={{ height: "30px", width: "80px" }} />

                      <Skeleton style={{ height: "30px", width: "60px" }} />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {Allfounders?.map((item, i) => {
                  console.log(
                    " item?.date?.split('-')?.[0]",
                    item?.date?.split(",")?.[0]?.split("/")?.[0]
                  );
                  return (
                    <div className="contain-bottom">
                      <h1
                        style={{
                          width: "3%",
                        }}
                      >
                        {i + 1}
                      </h1>
                      <h1
                        style={{
                          width: "35%",
                        }}
                      >
                        <img
                          style={{
                            height: "27.48px",
                            marginRight: "10px",
                          }}
                          src={item?.brand_logo}
                        />
                        {item?.brand_name}
                      </h1>
                      <h1
                        style={{
                          width: "27%",
                        }}
                      >
                        <img
                          style={{
                            height: "27.48px",
                            marginRight: "10px",
                            width: "27.48px",
                            borderRadius: "500px",
                          }}
                          src={item?.brand_country?.image}
                        />
                        {item?.brand_country?.name}
                      </h1>

                      <h1
                        style={{
                          width: "25%",
                        }}
                      >
                        {item?.app_count} Apps
                      </h1>

                      <h1
                        style={{
                          width: "10%",
                        }}
                      >
                        {formatNumber(item?.total_users_count)} Users
                      </h1>
                    </div>
                  );
                })}
              </>
            )}
          </>
        );

      case "Applications":
        return (
          <>
            {loader ? (
              <>
                {[1, 2, 2, 2, 2, 2, 2, 2].map((item) => {
                  return (
                    <div className="contain-bottom">
                      <Skeleton style={{ height: "30px", width: "60px" }} />
                      <Skeleton style={{ height: "30px", width: "80px" }} />

                      <Skeleton style={{ height: "30px", width: "80px" }} />

                      <Skeleton style={{ height: "30px", width: "60px" }} />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {AllApps?.map((item, i) => {
                  console.log(
                    " item?.date?.split('-')?.[0]",
                    item?.date?.split(",")?.[0]?.split("/")?.[0]
                  );
                  return (
                    <div className="contain-bottom">
                      <h1
                        style={{
                          width: "3%",
                        }}
                      >
                        {i + 1}
                      </h1>
                      <h1
                        style={{
                          width: "43%",
                        }}
                      >
                        <img
                          style={{
                            height: "27.48px",
                            marginRight: "10px",
                          }}
                          src={item?.app_icon}
                        />
                        {item?.app_name}
                      </h1>

                      <h1
                        style={{
                          width: "43%",
                        }}
                      >
                        {mS[item?.date?.split(",")?.[0]?.split("/")?.[0] - 1]}{" "}
                        {ordinal_suffix_of(
                          item?.date?.split(",")?.[0]?.split("/")?.[1]
                        )}{" "}
                        {item?.date?.split(",")?.[0]?.split("/")?.[2]}
                      </h1>

                      <h1
                        style={{
                          width: "11%",
                        }}
                      >
                        {formatNumber(item?.user_count)}
                      </h1>
                    </div>
                  );
                })}
              </>
            )}
          </>
        );
    }
  };

  return (
    <div className="tacker-main">
      <div className="tacker-menu">
        <img
          src={logo}
          onClick={() => navigate.push("/")}
          style={{
            width: "100%",
          }}
        />
        <label
          onClick={() =>
            window.open("https://app.marketsverse.com/register", "_blank")
          }
        >
          Launch App
        </label>
        <div className="slectedlabel">Currently Selected</div>
        {menu.map((item) => {
          return (
            <div
              style={
                item === "Global"
                  ? { background: "rgba(24, 37, 66, 0.05)", opacity: 1 }
                  : { position: "inherit" }
              }
              className="menu-tacker-list"
            >
              {item}
            </div>
          );
        })}

        <div
          className="slectedlabel"
          style={{
            marginTop: "3rem",
          }}
        >
          Countries
        </div>
        {menus.map((item) => {
          return (
            <div
              style={
                item === "Global"
                  ? { background: "rgba(24, 37, 66, 0.05)", opacity: 1 }
                  : {
                      position: "inherit",
                      opacity: 1,
                      border: " 1px solid #EFEFEF",
                    }
              }
              className="menu-tacker-list"
            >
              {item}
            </div>
          );
        })}
      </div>

      <div className="right-side-dah-tacker">
        <div className="tabs-tacker">
          <>
            <div className="tab-tacker-box">
              {tabs.map((item) => {
                return (
                  <div
                    className="tab-title"
                    onClick={() => settabName(item?.name)}
                    style={
                      tabName === item.name
                        ? { borderBottom: "1px solid #E7E7E7", opacity: 1 }
                        : { position: "inherit" }
                    }
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>

            <h1>Global Statistics</h1>
          </>
        </div>
        {/* */}
        {/* */}
        {tabName == "Usage" && <Usage />}
        {tabName == "Economy" && <Economy />}

        {tabName == "Vault" && <Vault />}
        {/* <Vault/>  */}

        {/* */}

        {/*  */}
      </div>
    </div>
  );
}
