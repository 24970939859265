import React from "react";
import ReactDOM from "react-dom";
import CryptoCouponsApp from "./CryptoCouponsApp";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from 'react-router-dom';
import './index.css'
import MainContextProvider from "./context/newContext";
ReactDOM.render(

    <BrowserRouter>
    <MainContextProvider>
      <CryptoCouponsApp />
    </MainContextProvider>
    </BrowserRouter>,

  document.getElementById("root")
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
