import React,{useEffect,useContext,useState} from 'react'

import canda from '../../Static/images/canda.png'
import extebd from '../../Static/images/extebd.png'
import { Modal } from 'react-bootstrap'

import { useHistory, useParams } from "react-router-dom";

import { Agency } from '../../context/Context';
import './Custom.scss'

export default function MapCorosule() {
  const history = useHistory();
  const agency = useContext(Agency)
  const {flagimage,flagname,modelmap,handleClose } = agency;
  
     
    return (
        <>

<div className="custom"> 


        <Modal show={modelmap} onHide={handleClose} dialogClassName="my-maps"  backdropClassName="customamp"  aria-labelledby="contained-modalter" centered>
           

   <Modal.Body>
       <div className="cusom">
         <div className="cusomsub">

        
         <div className="sub">
         <img src={canda} alt="" />
         <p>{flagname}</p>
   
         </div>


<div className="sub1">
  <img src={extebd} alt="" />
</div>
</div>
      <div className="tabcontiner">
          <p style={{fontWeight:"bold"}}>Businesses</p>
<p>Establishments</p>
<p>Social Work</p>
      </div>
      <div >

      </div>
       </div>

   </Modal.Body>

</Modal>
</div>


</>
    )
}
