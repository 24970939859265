import React, {useState } from 'react';
import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";
import NavBarcustom from './NavbarCutom'
import MapChart from "./GlobalMap";
import Toggle from './MapCorosule'
import './Glomap.scss'
function WorldMap() {
  const [content, setContent] = useState("");
  console.log("country", content)
  return (
       <>
       {/* <NavBarcustom/> */}
      <div className="GoogleMap" id="Our Footprint" key="3">
        <MapChart setTooltipContent={setContent} />
        <ReactTooltip>
         {content}
            
    </ReactTooltip>
        <Toggle/>
        
              </div>
         </>
      
    );
  }

export default WorldMap;
