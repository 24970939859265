import Routes from "./Routes";
import React, { useEffect } from "react";
// import "./Static/scss/master.scss";
import { AgencyProvider } from "./context/Context";

import "bootstrap/dist/css/bootstrap.min.css";
function CryptoCouponsApp() {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <AgencyProvider>
      <Routes />
    </AgencyProvider>
  );
}

export default CryptoCouponsApp;
