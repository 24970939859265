import React from "react";
import "./Holiding.scss";
import Navbar from "../components/navbar/navbar";
import { useParams, useHistory } from "react-router-dom";

import inner1 from "../Image/pp1.svg";
import logo1 from "../Image/logo1.png";
import upper from "../Image/starrcheck.svg";

import small2 from "../Image/pp2.svg";
import small3 from "../Image/pp3.svg";
import small4 from "../Image/pp4.svg";
import small5 from "../Image/pp5.svg";
import small6 from "../Image/pp6.svg";
export default function Holiding({setshow,setselectedtab,
  setmodelopen}) {
  const history = useHistory();
  return (
    <>
      {/* <Navbar /> */}
      <div className="HoldingPage bngh">
      <div className="topheadearhoilder">
              <h1>Our Brands</h1>


              <p
               onClick={()=>
                
                {
                  setselectedtab("hoilding");
                  setmodelopen(true)
                }}>Click Here To See The Valuation Of Our Portfolio In Live Time</p>
              {/* <div className="porprotfoilo"
             
                
              >See Our Portfolio</div> */}
              </div>
        <div className="titleMain"
        onClick={()=>setshow("default")}
        >
          <div className="inner">
            {/* <img src={logo1} /> */}
            <h2>Enterprise Tech</h2>
          </div>
          <img  className="rotatetranfrom" src={upper}  />
        </div>

        <div className="title">
          <div className="tileName">Company</div>
          <div className="tileName">Overview</div>
          <div className="tileName">Status</div>
          <div className="tileName">Position</div>
          <div className="tileName">Website</div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={inner1} />
              <h6>Marketsverse</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A platform where entrepreneurs can design, deploy, and grow fully branded applications for their business.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Wholly Owned</h5>
          </div>
          <div className="contentItem">
            <label
            
            onClick={()=>window.open("https://marketsverse.com","_blank")}
            
            >Website</label>
          </div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small2} />
              <h6>Viralverse</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A network of social commerce apps connecting brands, influencers and affiliates.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Development</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Wholly Owned</h5>
          </div>
          <div className="contentItem">
            <label
               onClick={()=>window.open("https://viral.group","_blank")}
            >Website</label>
          </div>
        </div>


        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small3} />
              <h6>IndianInvestor</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A capital market for startups to trade equities, form strategic alliances and find synergies
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Pre-Launch</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Wholly Owned</h5>
          </div>
          <div className="contentItem">
            <label
                       onClick={()=>window.open("https://indianinvestor.com","_blank")}
            >Website</label>
          </div>
        </div>

        
        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small4} />
              <h6>Garage</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A true marketplace where startups can find solutions ranging from a logo design to office space, just a click away
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Development</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Wholly Owned</h5>
          </div>
          <div className="contentItem">
            <label
                   onClick={()=>window.open("https://innovationgarage.in","_blank")}
            >Website</label>
          </div>
        </div>

     
        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small5} />
              <h6>Employment Group</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A suite of tools that enable companies to gamify, monitor, and incentivize their remote workforce. 
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Pre-Launch</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Wholly Owned</h5>
          </div>
          <div className="contentItem">
            <label
                   onClick={()=>window.open("https://employment.group","_blank")}
            >Website</label>
          </div>
        </div>


             
        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small6} />
              <h6>StartupBrokers</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A news/directory platform for startups, VC’s and entrepreneurs to track deals.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Pre-Launch</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Wholly Owned</h5>
          </div>
          <div className="contentItem">
            <label
                   onClick={()=>window.open("https://employment.group","_blank")}
            >Website</label>
          </div>
        </div>

{/* 

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small6} />
              <h6>IndianMarket</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A plugin to IndianInvestor that allows foreign investors to participate in its secondary markets 
            </p>
          </div>
          <div className="contentItem">
            <h5>Development</h5>
          </div>
          <div className="contentItem">
            <h5>Wholly Owned</h5>
          </div>
          <div className="contentItem">
            <label>Website</label>
          </div>
        </div> */}
      </div>
    </>
  );
}
