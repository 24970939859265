// import React, {useEffect, useState,  useContext } from 'react';
// import { useNavigate } from "react-router-dom";
// import { GlobalContex } from "../../../globalContex";
// import './landingPage.scss';
// import rotatemvs from "../../../Static/images/about/log.svg";
// import bluearrow from "../../../Static/arrow.svg";
// import greyarrow from "../../../Static/images/greyarrow.svg";
// import About from '../About';
// import EnpHome from '../../../entrepreneursPages/enpHomePage/enpHome';

// const LandingPage = () => {
//     const {showabout, setshowabout} = useContext(GlobalContex);
//     const [showbtns, setshowbtns] = useState(false);

//     const navigate = useNavigate();
//     useEffect(() => {
//         setTimeout(() => {
//             setshowbtns(true)
//         }, 2500);
//     }, [])
//   return (
//     // <>
//     // {
//     // showabout === "Enterprises" ?
//     // <About/>
//     // :
//     // showabout === "Entrepreneurs" ?
//     // <EnpHome/>
//     // :
//     <div className="landing-main-box">
//         <div className="left-btn-box" >
//             <div className={showbtns ? "btns-each" : ""} style={{display:showbtns ? "" : "none"}}>
//                 <div>
//                     <div className='type-name'>MarketsOS</div>
//                     <div className='hover-text'>Our App-As-A-Service platform which allows businesses to design, deploy, and manage powerful applications in minutes.</div>
//                     <label onClick={() => navigate("/marketos")}>Enter</label>
//                 </div>

//                 <div className="btn-img-box" onClick={() => navigate("/marketos")}>
//                     <img className="btn-img-icon" src={bluearrow} alt="" />
//                 </div>
//             </div>
//         </div>
//         <div className="main-img-box">
//             <img className="main-img-icon rotating" src={rotatemvs} alt="" />
//         </div>
//         <div className="right-btn-box" >
//             <div className={showbtns ? "btns-each1" : ""} style={{display:showbtns ? "" : "none"}}>
//                 <div>
//                     <div className='type-name'>Gravitichain</div>
//                     <div  className='hover-text1'>Our AI & Blockchain focused software development company which builds customized products for startups.</div>
//                     <label
//                        onClick={() => window.open("https://gravitichain.com/", "_blank")}
//                     >Enter</label>
//                 </div>

//                 <div className="btn-img-box"
//                 onClick={() => window.open("https://gravitichain.com/", "_blank")}
//                 >
//                     <img className="btn-img-icon" src={bluearrow} alt="" />
//                 </div>
//             </div>
//         </div>
//     </div>

//     // }
//     // </>
//   )
// }

// export default LandingPage

import React ,{useState}from "react";
import { useHistory } from "react-router-dom";
import k1 from "../Static/k1.png";
import k2 from "../Static/ga.png";
import k3 from "../Static/k3.png";
import k4 from "../Static/k4.png";
import l1 from "../Static/l1.svg";

import lastid from "../Static/lastid.png";
import l2 from "../Static/l2.svg";
import l3 from "../Static/gravs.svg";
import l4 from "../Static/l4.svg";
import c1 from "../Static/c1.svg";
import c2 from "../Static/c2.svg";
import c3 from "../Static/c3.svg";
import c4 from "../Static/c4.svg";
import in1 from "../Static/in1.png";
import cover from "../Static/cover.svg";

import crossback from "../Static/crossback.svg";
import inrwhite from "../Static/inrwhite.svg";
import llogo from "../Static/starlish.svg";
import "./landing.scss";
export default function LandingPage() {
  const [toggle,settoggle]=useState(false)
  const navigate = useHistory();
  return (
    <>

    {/* <div className={toggle?"drawertrue":"drawer "}>
<img src={crossback}
       onClick={()=>settoggle(false)}
/>
<div className="inner">
  <h1>About</h1>
  <h1>Team</h1>
  <h1>Holdings</h1>
  <h1>Product Lines</h1>
  <h1>Services</h1>
  <h1>Contact</h1>
</div>
    </div> */}
      {/* <div className="navlogo" >
        <img className="img" src={cover} 
        
        onClick={()=>settoggle(true)}
        />
        <img src={llogo}  onClick={()=>settoggle(false)}/>
        <div className="super" 
        onClick={()=>settoggle(false)}
        >Apply For SuperNova</div>
      </div> */}
      <div id="Products" className="Products"  
      onClick={()=>settoggle(false)}
      >
        <div className="cardSection">
        <div class="flip flip-vertical">
            <div class="front" style={{ backgroundImage: `url(${k4})` }}>
              <img src={l4} />
              <h1 class="text-shadow">Viralverse</h1>
              <p>Social Commerce As A Service</p>
            </div>
            <div class="back">
              <img className="backImg" src={c4} />
              <h2 class="text-shadow" style={{ color: "#CF1A38" }}>
                Viralverse
              </h2>
              <h6 style={{ color: "#100F0D" }}>Social Commerce As A Service</h6>
              <h4 style={{ color: "#100F0D" }}>
              The ultimate social marketing ecosystem for your product or service. Incentive your customers, increase engagement and go viral.
            
            
              </h4>

              <div className="about-secton">
                <label
                  style={{
                    color: "#fff",
                    background: "#CF1A38",
                  }}
                  onClick={() =>
                    window.open("https://viral.group", "_blank")
                  }
                >
                  Explore
                </label>
              </div>
            </div>
          </div>
          <div class="flip flip-vertical">
            <div class="front" style={{ backgroundImage: `url(${k1})` }}>
              <img src={l1} />
              <h1 class="text-shadow"> Marketsverse</h1>
              <p>App As A Service</p>
            </div>
            <div class="back">
              <img className="backImg" src={c1} />
              <h2
                class="text-shadow"
                style={{
                  background:
                    "linear-gradient(90deg, #277EFB 0%, #B580F2 121.81%)",
                  backgroundClip: "text",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  // webkitBackgroundClip:"text",
                  WebkitTextFillColor: "transparent",
                  webkitBackgroundClip: "text",

                  textFillCcolor: "transparent",
                }}
              >
                Marketsverse
              </h2>
              <h6 style={{ color: "#100F0D" }}>App As A Service</h6>
              <h4 style={{ color: "#100F0D" }}>
              Our App-As-A-Service platform which allows businesses to design, deploy, and manage powerful applications in minutes.
              </h4>

              <div className="about-secton">
                <label
                  // onClick={() => navigate("/marketos")}
                  style={{
                    color: "#fff",
                    background:
                      "linear-gradient(275.12deg, #277EFB 7.36%, #B580F2 140.3%)",
                  }}
                >
                  Explore
                </label>
              </div>
            </div>
          </div>

          <div class="flip flip-vertical">
            <div class="front" style={{ backgroundImage: `url(${k2})` }}>
              <img src={l2} />
              <h1 class="text-shadow">Garage</h1>
              <p>Incubation As A Service </p>
            </div>
            <div class="back">
              <img className="backImg" src={c2} />
              <h2
                class="text-shadow"
                style={{
                  color: "#59A2DD",
                }}
              >
                Garage
              </h2>
              <h6 style={{ color: "#100F0D" }}>Incubation As A Service </h6>
              <h4 style={{ color: "#100F0D" }}>
              A true marketplace where startups can find solutions ranging from a logo design to office space, just a click away
              </h4>
              <div className="about-secton">
                <label
                  style={{
                    color: "#fff",
                    background: "#59A2DD",
                  }}
                  onClick={() =>
                    window.open("https://innovationgarage.in", "_blank")
                  }
                >
                  Explore
                </label>
              </div>
            </div>
          </div>
          

          <div class="flip flip-vertical">
            <div class="front" style={{ backgroundImage: `url(${k3})` }}>
              <img src={l3} />
              <h1 class="text-shadow">Gravitichain</h1>
              <p>Growth As A Service</p>
            </div>
            <div class="back">
              <img className="backImg" src={c3} />
              <h2 class="text-shadow" style={{ color: "#EE2D68" }}>
                Gravitichain
              </h2>
              <h6 style={{ color: "#100F0D" }}>Growth As A Service</h6>
              <h4 style={{ color: "#100F0D" }}>
              A growth vertical dedicated to startups at any stage, helping with go-to-market strategies, sales acceleration, quality lead generation, and brand building.
              </h4>

              <div className="about-secton">
                <label
                  onClick={() =>
                    window.open("https://gravitichain.com/", "_blank")
                  }
                  style={{
                    color: "#fff",
                    background: "#EE2D68",
                  }}
                >
                  Explore
                </label>
              </div>
            </div>
          </div>
          <div class="flip flip-vertical">
            <div class="front" style={{ backgroundImage: `url(${in1})` }}>
              <img src={inrwhite} />
              <h1 class="text-shadow">IndianInvestor</h1>
              <p>Capital Markets As A Service</p>
            </div>
            <div class="back">
              <img className="backImg" src={lastid} />
              <h2 class="text-shadow" style={{ color: "#4CAF50" }}>
              IndianInvestor
              </h2>
              <h6 style={{ color: "#100F0D" }}>Capital Markets As A Service</h6>
              <h4 style={{ color: "#100F0D" }}>
              A capital market for startups to trade equities, form strategic alliances and find synergies
              </h4>

              <div className="about-secton">
                <label
                  style={{
                    color: "#fff",
                    background: "#4CAF50",
                  }}
                  onClick={() =>
                    window.open("https://indianinvestor.com", "_blank")
                  }
                >
                  Explore
                </label>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
