import React, { useState } from "react";
import "./Portfolio.scss";

import Holdings from "../../components/Holdings/holdings"
import Equity from "../../components/Equity/equity"
import Navbar from "../../components/navbar/navbar";
function Portfolio() {
    let [theid, settheid] = useState(0)
    let [thename, setthename] = useState("All Holdings")
    const TABS = [
        { id: 0, name: "All Holdings" },
        { id: 1, name: "Private Equity" },
        { id: 2, name: "Crypto" },
        { id: 3, name: "NFT’s" },
        { id: 4, name: "Public Equity" },
        { id: 5, name: "Real Estate" },
        { id: 6, name: "Managed Funds" },
        { id: 7, name: "Other" },
    ];



    const thetab = (e) => {
        console.log(e.target.innerText)
        settheid(e.currentTarget.id)
        setthename(e.target.innerText)
        // console.log(document.getElementById("tab-button").value)
    }
    let tabdata
    switch (thename) {
        case "All Holdings":
            tabdata = <Holdings />
            break;
        case "Private Equity":
            tabdata = <Equity />
            break;
    }

    return (
        <>
         <Navbar/>
            <div className="main-card">
                <div className="disp-tab-card">
                    <div className="headerTabs">
                        {TABS.map((tab, i) => {
                            // console.log(i , parseInt(theid))
                            return (
                                <>
                                    <div onClick={thetab} id={tab.id}
                                        className={`tabItm ${[(i === parseInt(theid)).toString()]
                                            }`}
                                    >
                                        {tab.name}
                                    </div>
                                    <div className="vl"></div>
                                </>
                            )
                        })}
                    </div>
                    <div className="tabs-data">
                        {tabdata}
                    </div>
                </div>
            </div>
       
        </>
    )
}

export default Portfolio