import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../../context/newContext";
import "./navbar.scss";
import mainicon from "../../Static/images/mainicon.svg";
import { useHistory } from "react-router-dom";
import cover from "../../Static/loginPage.svg";

import heartbeat from "../../Static/heartbeat.svg";
import jkk from "../../Static/jkk.svg";
import seacrh from "../../Static/seacrh.svg";
import axios from "axios";
import ind from "../../Static/ind.svg";
import down from "../../Static/down.svg";
import crossback from "../../Static/crossback.svg";
import donw from "../../Static/donw.svg";
import llogo from "../../Static/loginPage.svg";

const Navbar = ({ setmodelopen, setselectedtab }) => {
  let history = useHistory();
  const {
    nav,
    setnav,
    sideNav,
    setSideNav,
    collapsedSideBar,
    setCollapsedSideBar,
    navExpand,
    setMobileScreensNav,
    gotoTop,
    setgotoTop,
  } = useContext(MainContext);
  const [toggle, settoggle] = useState(false);
  const [counttoggle, setcounttoggle] = useState(false);
  const [type, settype] = useState("");
  const [datacountry, setdatacountry] = useState([]);
  const [innerMenu, setinnerMenu] = useState("")
  const [selectitem, setselectitem] = useState({
    image: ind,
    name: "India",
  });
  //  const GetAppData = (parentgroup) => {
  //   try{
  //     const response = axios.get(`https://comms.globalxchange.io/coin/vault/countries/data/get`);
  //     return response;
  //   }catch(error){
  //     return error.response
  //   }
  // }

  useEffect(() => {
    fethimgae();
  }, []);
  const fethimgae = async () => {
    let data = await axios.get(
      "https://comms.globalxchange.io/coin/vault/countries/data/get"
    );
    setdatacountry(data?.data?.countries);
    console.log("czxcxzczxc", data?.data?.countries);
    // setelemetitem(data.data.groups)
    // setidicate(false)
    // setbackgroudimage(data.data.apps[0].cover_photo);
    // setappicon(data.data.apps[0].app_icon);
  };

  const submenu = [
    {
      name: 'Home',
      url: "/",
    },
    {
      name: 'Story',
      url: "/about/story",
    },
    {
      name: 'Team',
      url: "/about/team",
    },
    {
      name: 'Footprint',
      url: "/",
    },
    {
      name: 'Roadmap',
      url: "/",
    },

  ]
  const submenuhoilding = [
    {
      name: 'Enterprise Tech',
      url: "/holidings/enterprise",
    },
    {
      name: 'Consumer Tech',
      url: "/holidings/consumer",
    },
    {
      name: 'Offline',
      url: "/holidings/offline",
    },
    {
      name: 'Other',
      url: "/holidings/other",
    },


  ]

  const [seletedMenu, setseletedMenu] = useState(submenu)

  const dataflag = [
    {
      name: "India",
      img: ind,
    },
    {
      name: "Canada",
      img: ind,
    },
    {
      name: "United Kingdom",
      img: ind,
    },
    {
      name: "United Arab Emirates",
      img: ind,
    },
    {
      name: "Canada",
      img: ind,
    },
    {
      name: "United Kingdom",
      img: ind,
    },
  ];

  const handleNavMbile = (item, itemName) => {

    if (item === "home") {
      // setnav(itemName);
      var element = document.getElementById("home");

      // element.scrollIntoView({behavior: "smooth"});
      setgotoTop(true);
      setCollapsedSideBar(false);
      setMobileScreensNav("");
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      setnav(itemName);
      if (itemName === "Supernova") {

        history.push("/supernova")
      }
      if (itemName === "holiding") {
        setseletedMenu(submenuhoilding)


        // console.log(itemName)
        // setSideNav("")
        // // history.push("/subsidiaries");
        // var element = document.getElementById("menu");

        // element.scrollIntoView({ behavior: "smooth" });
        return
      }
      if (itemName === "Subsidiaries") {
        // console.log(itemName)
        // setSideNav("")
        // // history.push("/subsidiaries");
        // var element = document.getElementById("menu");

        // element.scrollIntoView({ behavior: "smooth" });
        history.push(`/${itemName.toLowerCase()}`);
      }

      else if (itemName === "Supernova") {


      }




      else if (itemName === "media") {

        history.push("/media")
        setseletedMenu([])
        settoggle(false);
        //  history.push(`/${itemName.toLowerCase()}`);
      }
      else if (itemName === "contact") {

        history.push("/contact")
        settoggle(false);
        setseletedMenu([])
        //  history.push(`/${itemName.toLowerCase()}`);
      }

      else if (itemName === "About") {

        setseletedMenu(submenu)
        //  history.push(`/${itemName.toLowerCase()}`);
      } else {
        setSideNav(0);
        var element = document.getElementById("menu");

        element?.scrollIntoView({ behavior: "smooth" });
      }
    }
  };





  const handleNav = (item, itemName) => {

    alert(itemName)
    if (item === "home") {
      // setnav(itemName);
      var element = document.getElementById("home");

      // element.scrollIntoView({behavior: "smooth"});
      setgotoTop(true);
      setCollapsedSideBar(false);
      setMobileScreensNav("");
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      setnav(itemName);
      if (itemName === "contact") {
        var element = document.getElementById("contact");
        history.push("/contact")
        element?.scrollIntoView({ behavior: "smooth" });
      }
      if (itemName === "holiding") {
        history.push("/holidings")
        // console.log(itemName)
        // setSideNav("")
        // // history.push("/subsidiaries");
        var element = document.getElementById("menuHolding");

        element?.scrollIntoView({ behavior: "smooth" });

      }
      if (itemName == "media") {
        history.push("/media")
        var element = document.getElementById("media");

        element?.scrollIntoView({ behavior: "smooth" });

      }

      if (itemName === "Supernova") {

        history.push("/supernova")
      }
      if (itemName === "Subsidiaries") {
        // console.log(itemName)
        // setSideNav("")
        // // history.push("/subsidiaries");
        // var element = document.getElementById("menu");

        // element.scrollIntoView({ behavior: "smooth" });
        history.push(`/${itemName.toLowerCase()}`);
      }
      if (itemName === "About") {
        history.push(`/${itemName.toLowerCase()}/story`);
        var element = document.getElementById("menu");

        element?.scrollIntoView({ behavior: "smooth" });
      } else {
        setSideNav(0);

      }
    }
  };

  const handleMobileNav = () => {
    setMobileScreensNav("About");
    setSideNav("");
  };
  return (
    <>
      {/* <div className="master-nav" style={{padding: navExpand? "0px 5vw" : "0px 10vw", transition:"all ease .5s"}}>
      <div className="nav-icon-box" onClick={() => handleNav("home", "home")}>
        <img src={mainicon} alt="" />
      </div>
      <div className="nav-menu-box">
        <div
          className="nav-each-menu"
          style={{ fontWeight: nav === "About" ? "600" : "" }}
          onClick={() => handleNav("menu", "About")}
        >
          About
        </div>
        <div
          className="nav-each-menu"
          style={{ fontWeight: nav === "Subsidiaries" ? "600" : "" }}
          onClick={() => handleNav("menu", "Subsidiaries")}
        >
          Subsidiaries
        </div>
        <div
          className="nav-each-menu"
          style={{ fontWeight: nav === "Development" ? "600" : "" }}
          onClick={() => window.open("https://block.software/", "_blank")}
        >
          Development
        </div>
        <div className="nav-button" onClick={() => window.open("https://marketsverse.com/", "_blank")}>MarketsVerse</div>
      </div>
      <div className="mob-nav1">
        <div className="cryptoimage" onClick={() => handleMobileNav()}>
          <img
            style={{ width: "140px", cursor: "pointer", }}
            src={mainicon}
            alt=""
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <svg
            className={
              !collapsedSideBar
                ? "ham hamRotate ham4"
                : "ham hamRotate ham4 active"
            }
            viewBox="0 0 100 100"
            width="80"
            onClick={() => setCollapsedSideBar(!collapsedSideBar)}
            style={{ height: "60px", width: "60px" }}
          >
            <path
              className="line top"
              d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"
            />
            <path className="line middle" d="m 70,50 h -40" />
            <path
              className="line bottom"
              d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"
            />
          </svg>

       
        </div>
       
      </div>
    </div> */}

      <div className={toggle ? "drawertrue" : "drawer "}>
        <img src={crossback} onClick={() => settoggle(false)} />

        <div className="inner">
          <div className="innsideflex">
            <h1
              onClick={() => {
                handleNavMbile("menu", "About");
                // settoggle(false);
              }}


              style={{
                color: nav === "About" && "white",
                paddingLeft: nav === "About" ? "1.4rem" : "0rem",
                fontWeight: nav === "About" && "600",
                background: nav === "About" && "linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%)",
              }}
            >
              About
            </h1>
            <h1
              onClick={() => {
                handleNavMbile("menu", "Supernova");
                settoggle(false);
              }}


              style={{
                color: nav === "Supernova" && "white",
                paddingLeft: nav === "Supernova" ? "1.4rem" : "0rem",
                fontWeight: nav === "Supernova" && "600",
                background: nav === "Supernova" && "linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%)",
              }}
            >
              Supernova
            </h1>

            <h1
              onClick={() => {
                handleNavMbile("menu", "holiding");
                // settoggle(false);
              }}


              style={{
                color: nav === "holiding" && "white",
                paddingLeft: nav === "holiding" ? "1.4rem" : "0rem",
                fontWeight: nav === "holiding" && "600",
                background: nav === "holiding" && "linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%)",
              }}
            >
              Holdings
            </h1>




            {/* <h1>Product Lines</h1> */}
            {/* <h1>Careers</h1> */}
            <h1
              onClick={() => {
                handleNavMbile("menu", "contact");
                // settoggle(false);
              }}
              style={{
                color: nav === "contact" && "white",
                paddingLeft: nav === "contact" ? "1.4rem" : "0rem",
                marginLeft: nav === "contact" && "-1rem",
                background: nav === "contact" && "linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%)",
              }}

            >Contact</h1>
            <h1
              onClick={() => {
                handleNavMbile("menu", "media");
                // settoggle(false);
              }}

              style={{
                color: nav === "media" && "white",
                paddingLeft: nav === "media" ? "1.4rem" : "0rem",
                fontWeight: nav === "media" && "600",
                background: nav === "media" && "linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%)",
              }}

            >Media</h1>
          </div>



          <div className="desktopland">
            <h1
              onClick={() => {
                handleNav("menu", "About");
                settoggle(false);
              }}


              style={{
                color: nav === "About" && "white",
                paddingLeft: nav === "About" ? "1.4rem" : "0rem",
                marginLeft: nav === "About" && "-1rem",
                background: nav === "About" && "linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%)",
              }}
            >
              About
            </h1>
            <h1
              onClick={() => {
                handleNav("menu", "Supernova");
                settoggle(false);
              }}


              style={{
                color: nav === "Supernova" && "white",
                paddingLeft: nav === "Supernova" ? "1.4rem" : "0rem",
                marginLeft: nav === "Supernova" && "-1rem",
                background: nav === "Supernova" && "linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%)",
              }}
            >
              Supernova
            </h1>

            <h1
              onClick={() => {
                handleNav("menu", "holiding");
                settoggle(false);
              }}


              style={{
                color: nav === "holiding" && "white",
                paddingLeft: nav === "holiding" ? "1.4rem" : "0rem",
                marginLeft: nav === "holiding" && "-1rem",
                background: nav === "holiding" && "linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%)",
              }}
            >
              Holdings
            </h1>




            {/* <h1>Product Lines</h1> */}
            {/* <h1>Careers</h1> */}
            <h1

              onClick={() => {
                handleNav("menu", "contact");
                settoggle(false);
              }}
              style={{
                color: nav === "contact" && "white",
                paddingLeft: nav === "contact" ? "1.4rem" : "0rem",
                marginLeft: nav === "contact" && "-1rem",
                background: nav === "contact" && "linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%)",
              }}

            >Contact</h1>
            <h1
              onClick={() => {
                handleNav("menu", "media");
                settoggle(false);
              }}


              style={{
                color: nav === "media" && "white",
                paddingLeft: nav === "media" ? "1.4rem" : "0rem",
                marginLeft: nav === "media" && "-1rem",
                background: nav === "media" && "linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%)",
              }}
            >Media</h1>
          </div>

          <div className="mobileinside">
            {
              seletedMenu.map(item => {
                return (
                  <h5
                    onClick={() => {
                      setinnerMenu(item.name);
                      settoggle(false);
                      history.push(item?.url)

                    }}
                    style={{
                      background: innerMenu == item?.name && "rgba(22, 23, 26, 0.03)",
                      fontWeight: innerMenu == item?.name && "600"
                    }}
                  >{item.name}</h5>
                )
              })
            }
          </div>
          {/* <div className="mobilegetStart">Get Started</div>
<div className="mobiletracker">Tracker</div> */}
        </div>



      </div>

      <div className="navlogo">
      <div className="inner">
        <div
          className="bordertable"
          onClick={() => {
            setcounttoggle(!counttoggle);
          }}
        >

          <img className="imgs" src={selectitem?.image} />
          <p>{selectitem?.name}</p>

          {counttoggle ? <img src={donw} /> : <img src={down} />}
        </div>
        {/*  */}
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          {
            toggle ?
              <img className="img" src={jkk} onClick={() => settoggle(false)} />
              :
              <img className="img" src={cover} onClick={() => settoggle(true)} />
          }



          <img
            src={llogo}
            onClick={() => {
              settoggle(false);
              history.push("/");
            }}
            className="mobileimg"
          />
        </div>



        {window.innerWidth > 900
          ? <div className="super"
          //  onClick={() => {
          //   settoggle(false);
          //   setmodelopen(true);
          //   setselectedtab("tracker")
          // }
          // }
          >
            <img src={heartbeat} />
          </div>
          : <div className="super"
          // onClick={() => {
          //   settoggle(false);
          //   setmodelopen(true);
          //   setselectedtab("tracker")

          // }
          // }

          >
            <img src={heartbeat} />
          </div>}
          </div>
      </div>

      {counttoggle && (
        <div
          className="dropdownforcountry"
          onMouseLeave={() => setcounttoggle(false)}
        >
          <div className="seaarchmatch">
            <img src={seacrh} />
            <input
              style={{ width: "100%", background: "transparent" }}
              value={type}
              onChange={(e) => settype(e.target.value)}
              placeholder="Search Your Country"
            />
          </div>
          <div className="dataCountry">
            {datacountry
              ?.filter((name) =>
                name?.name?.toLowerCase().includes(type?.toLowerCase())
              )
              ?.map((item) => {
                return (
                  <div
                    className="dropdoqninside"
                    onClick={() => {
                      setselectitem({
                        image: item?.image,
                        name: item?.name,
                      });
                      setcounttoggle(false);
                    }}
                  >
                    <img src={item?.image} />
                    <p>{item.name}</p>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
