import React from "react";
import "./equity.scss";
import ArrowDown from "../../Static/arrowdown.svg";
import TaxChainIcon from "../../Static/taxchainicon1.svg";
import IndiaIcon from "../../Static/indiaicon.svg";

function Equity() {
    return (
        <>
            <div className="tab2-align">
                <p className="hashtag tabbar">#</p>
                <p className="asset tabbar">Asset</p>
                <p className="price tabbar">Price Per Asset</p>
                <p className="hold tabbar">Holdings</p>
                <p className="val tabbar">Value</p>
                <p className="roi tabbar">ROI&nbsp;<span><img src={ArrowDown} alt="arrow-down" /></span></p>
            </div>
            <hr className="equity-tag" />
            <div className="equity-data">
                <p className="equity-num">1</p>
                <div className="equity-name">
                    <img src={TaxChainIcon} alt="tax-chainicon" /><span className="asset-name">TaxChains Share Token</span>
                    <div className="asset-symbol">
                        <p className="asset-tag">TXCE</p>
                        <img src={IndiaIcon} alt="indicon" className="asset-img"/>
                        <p className="asset-curr">INR</p>
                    </div>
                </div>
                <p className="equity-price">₹6,437.43</p>
                <p className="equity-hold">₹437,578,765.43</p>
                <p className="equity-value">₹437,578,765.43</p>
                <p className="equity-roi">₹0.00(0.00%)</p>
            </div>
            <hr className="hr-sty-equ"/>
        </>
    )
}

export default Equity