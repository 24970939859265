import React, { useEffect } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Tracker from "./pages/Tracker";
import AOS from "aos";
import NewHomePage from "./pages/newHomePage";
import Privacy from "./pages/Privacy";
import Cancellation from "./pages/Cancellation";
import Tos from "./pages/Tos";
import ContactUs from "./pages/ContactusPage";
import PartnerShips from "./pages/partnershipsPage";
import InvestorRelation from "./pages/InvestorRelation";
import Protfolio from "./pages/Profolio/Profolio";
import Holiding from "./pages/Holiding";
import Super from "./pages/Super";
import Media from "./pages/Media";
function Routes() {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
    return () => {};
  }, []);

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/" component={NewHomePage} />
        <Route path="/about" component={NewHomePage} />
        <Route path="/media" component={NewHomePage} />
        <Route path="/tracker" component={Tracker} />

        <Route exact path="/holidings" component={NewHomePage} />
        <Route exact path="/supernova" component={Super} />
        <Route exact path="/holidings/:name" component={NewHomePage} />
        <Route exact path="/portfolio" component={Protfolio} />
        <Route path="/product lines" component={NewHomePage} />
        <Route path="/starfish ventures" component={NewHomePage} />
        <Route exact path="/contact" component={NewHomePage} />
        <Route exact path="/contact/partnerships" component={PartnerShips} />
        <Route
          exact
          path="/contact/investorRelation"
          component={InvestorRelation}
        />
        <Route exact path="/tos" component={Tos} />
        <Route exact path="/Privacypolicy" component={Privacy} />
        <Route exact path="/cancellation" component={Cancellation} />
      </Switch>
    </React.Fragment>
  );
}

export default Routes;
