import React, { Component, createContext } from "react";

import axios from "axios";


export const Agency = createContext();
export class AgencyProvider extends Component {
  
  state = {
    
banner:false,
compainesvalue:[],
dupicatecompainesvalue:[],
lengthvalue:0,
idicatebolvalue:true,
idicatearray:[],
dupicateidicatearray:[],
imageloading:true,
flagimage:"",
modelmap:false,
flag:[],
flagname:"",
valuecompaines:"",
indicatecount:""
  };
functionbanner=()=>{
  this.setState({
    banner:true
  })
}
componentDidMount=()=>{
  this.fethbrandimgae()
this.fethbarandimgae()
 this.flagfunction()

}

 handleatlasChangefliterAtlas = async e => {

  let value =e.target.value;

  let n1= this.state.dupicatecompainesvalue.filter((user)=>{
    return (user.name).toUpperCase().includes((value).toUpperCase());
    
   })

   this.setState({
    compainesvalue:n1,
valuecompaines:value
    
  })

}

idicatefliter = async e => {

  let value =e.target.value;

  let n1= this.state.dupicateidicatearray.filter((user)=>{
    return (user.groupname).toUpperCase().includes((value).toUpperCase());
    
   })

   this.setState({
    idicatearray:n1,

    
  })

}

 fethbarandimgae=async()=>{

  let data = await axios.get(
    "https://comms.globalxchange.io/gxb/app/gxlive/operator/app/groups/get"
  );
  if (data.data.status) {
    console.log("data.data.apps", data.data.apps);
    this.setState({
      idicatearray:data.data.groups,
indicatecount:data.data.count,
dupicateidicatearray:data.data.groups,
      
    })
    setTimeout(() => this.setState({ idicatebolvalue: false}), 3000)
    setTimeout(() => this.setState({ imageloading: false}), 6000)
  }

 
 

  

    // setbackgroudimage(data.data.apps[0].cover_photo);
    // setappicon(data.data.apps[0].app_icon);
  }


  flagfunction=async()=>{

    let data = await axios.get(
      "https://countriesnow.space/api/v0.1/countries/flag/images"
    );
    if (data.data.status) {

    this.setState({
      flag:data.data.data,
     
    })
  }
}
handleClose=()=>{
  this.setState({
   
   modelmap:false
  })
}
   flitermapfunction=(e)=>{
   
    let n1 = this.state.flag.filter((user) => {
      return (user.flag).toUpperCase().includes((e).toUpperCase());
    });
  
    this.setState({
      flagimage:n1,
     flagname:e,
   
    })
if(e=="Canada"||e=="India" || e=="Australia" || e=="Spain"||e=="United Kingdom")
{
  this.setState({
  
   modelmap:true
  })
}
   }

   fethbrandimgae=async()=>{

    let data = await axios.get(
      "https://comms.globalxchange.io/gxb/app/gxlive/operator/brands/get"
    );
    if (data.data.status) {

    this.setState({
      compainesvalue:data.data.brands,
      dupicatecompainesvalue:data.data.brands,
      lengthvalue:data.data.count
    })
  }
   
    }
  
  


  render() {
    return (
      <Agency.Provider
        value={{
          ...this.state,
          functionbanner:this.functionbanner,
          flitermapfunction:this.flitermapfunction,
          handleClose:this.handleClose,
          fethbrandimgae:this.fethbrandimgae,
          handleatlasChangefliterAtlas:this.handleatlasChangefliterAtlas,
          idicatefliter:this.idicatefliter,
          fethbarandimgae:this.fethbarandimgae,
        }}
      >
        {/* <ContextDevTool context={Agency} id="uniqContextId" displayName="Context Display Name" /> */}
        {this.props.children}
      </Agency.Provider>
    );
  }
}
