import React, { useState, useContext, useLayoutEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MainContext } from "../context/newContext";
import Navbar from "../components/navbar/navbar";
import "./newHomePage.scss";
import "./HomeNvest.scss";
import axiosimg from '../Static/axiosimg.svg'
import Googlemap from "../components/HomePage/ToolMap";
import founderimg from "../Static/images/founderimg.svg";
import clickarrow from "../Static/bd.svg";
import time1 from "../Static/images/time1.svg";
import time2 from "../Static/images/time2.svg";
import leftarrow from "../Static/images/leftarrow.svg";
import right from "../Static/images/right.svg";
import elipse from "../Static/images/elipse.svg";
import gxsmalllogo from "../Static/images/gx-small-logo.svg";
import inrlogo from "../Static/images/inrlogo.svg";
import virual from "../Static/images/virual.svg";
import employement from "../Static/images/employement.svg";
import expirence from "../Static/images/expirence.svg";
import vchain from "../Static/images/vchain.svg";
import ered from "../Static/images/ered.svg";
import shorupn from "../Static/shorupn.svg";
import Hoilding from "../pages/Holiding"
import ContactUs from "./ContactusPage";
import Founders from './Founders'
//image box
import imageSection from '../Image/imageSection.svg'
import img1 from "../Static/t1.svg";
import img2 from "../Static/t9.svg";
import img3 from "../Static/t10.svg";
import img4 from "../Static/t2.svg";
import img5 from "../Static/t3.svg";
import img6 from "../Static/t4.svg";
import img7 from "../Static/t5.svg";
import img8 from "../Static/t7.svg";
import img9 from "../Static/t8.svg";
import horzline from "../Static/images/horzline.svg";
import vertline from "../Static/images/vertline.svg";
import TimeLine from "../components/Timeline/Timeline";
import Mobnav from "../components/navbar/mobnav";
import Subsidiaries from "./subsidiaries";
import SubSubsidiaries from "./subsubsidary";
import { useEffect } from "react";
import Landing from "./LandingPage";
import Media from '../pages/Media'
import { Modal } from 'react-bootstrap'
import OtpInput from 'react-otp-input';
import MobileHading from './MobileHolding'
import cock from '../Static/cock.svg'
import nvest from '../Static/nvest.svg'
import Footer from './Footer'
import vidofile from '../Static/vidofile.mp4'
const sec1Boxes = [
  {
    id: 0,
    title: "For Insurers",
    desc: "Digitalize your brokerage, PEO, TPA, or HR department with our white label benefits & wellness platform.",
    desc1: " ",
    year: "",
  },
  {
    id: 1,
    title: "For Healthcare Providers",
    desc: "Streamline your clinic, pharmacy, or consultancy with our Ai enabled practice management suite.",
    desc1: "",
    year: "",
  },
  {
    id: 2,
    title: "For Healthcare Startups",
    desc: "Distribute your product to thousands of users through our insurance integrated wellness marketplace.",
    desc1: "",
    year: "",
  },
];

const sideBar = [
  {
    title: "About",
    subMenu: ["Founders", "Executives", "Investors", "Advisors", "Footprint", "Roadmap"],
  },
  // {
  //   title: "Product Lines",
  //   subMenu: [
  //     "Marketsverse",
  //     "Gravitichain",
  //     "Viralverse",
  //     "Garage",
  //     "IndianInvestor",
  //     "StartupBrokers",
  //     // "Spending",
  //     // "Businesses",
  //   ],
  // },
  // {
  //   title: "Starfish Ventures",
  //   subMenu: ["Ai", "Tech", "Offline"],
  // },
];

const allmenus = [
  {

    title: "About",
    menu: "Advisors",
    num: 88,
    link: "/about/Advisors",
  },
  {

    title: "About",
    menu: "Investors",
    num: 788,
    link: "/about/investors",
  },

  {

    title: "About",
    menu: "Founders",
    num: 199,
    link: "/about/founders",
  },
  {
    title: "About",
    menu: "Home",
    num: 99,
    link: "/",
  },
  {
    title: "holidings",
    menu: "holidings",
    num: 33,
    link: "/holidings/enterprise",
  },
  {
    title: "media",
    menu: "media",
    num: 44,
    link: "/media",
  },
  {
    title: "contact",
    menu: "contact",
    num: 55,
    link: "/holidings/enterprise",
  },
  {
    title: "holidings",
    menu: "holidings",
    num: 33,
    link: "/holidings/consumer",
  },
  {
    title: "holidings",
    menu: "holidings",
    num: 33,
    link: "/holidings/offline",
  },

  {
    title: "About",
    menu: "About",
    num: 0,
    link: "/about",
  },
  {
    title: "About",
    menu: "Story",
    num: 0,
    link: "/about/story",
  },
  {
    title: "About",
    menu: "Story",
    num: 0,
    link: "/about/story/nvestbank",
  },
  {
    title: "About",
    menu: "Story",
    num: 0,
    link: "/about/story/gravitichain",
  },
  {
    title: "About",
    menu: "Story",
    num: 0,
    link: "/about/story/nvestglobal",
  },
  {
    title: "About",
    menu: "Story",
    num: 0,
    link: "/about/story/globalxchange",
  },
  {
    title: "About",
    menu: "Story",
    num: 0,
    link: "/about/story/nvestcampus",
  },
  {
    title: "About",
    menu: "Story",
    num: 0,
    link: "/about/story/teamforce",
  },
  {
    title: "About",
    menu: "Story",
    num: 0,
    link: "/about/story/viralgroup",
  },
  {
    title: "About",
    menu: "Story",
    num: 0,
    link: "/about/story/marketsverse",
  },
  {
    title: "About",
    menu: "Story",
    num: 0,
    link: "/about/story/inrgroup",
  },
  {
    title: "About",
    menu: "Story",
    num: 0,
    link: "/about/story/entertainmentgroup",
  },
  {
    title: "About",
    menu: "Story",
    num: 0,
    link: "/about/story/gx3.0",
  },
  {
    title: "About",
    menu: "Executives",
    num: 1,
    link: "/about/Executives",
  },
  {
    title: "About",
    menu: "Footprint",
    num: 2,
    link: "/about/Footprint",
  },
  {
    title: "About",
    menu: "Roadmap",
    num: 3,
    link: "/about/Roadmap",
  },
  {
    title: "Product Lines",
    menu: "Product Lines",
    num: "",
    link: "/Product Lines",
  },
  {
    title: "Product Lines",
    menu: "Marketsverse",
    num: 0,
    link: "/Product Lines/Marketsverse",
  },
  {
    title: "Product Lines",
    menu: "Gravitichain",
    num: 1,
    link: "/Product Lines/Gravitichain",
  },
  {
    title: "Product Lines",
    menu: "Viralverse",
    num: 2,
    link: "/Product Lines/Viralverse",
  },
  {
    title: "Product Lines",
    menu: "Garage",
    num: 3,
    link: "/Product Lines/Garage",
  },
  {
    title: "Product Lines",
    menu: "IndianInvestor",
    num: 4,
    link: "/Product Lines/IndianInvestor",
  },
  {
    title: "Product Lines",
    menu: "StartupBrokers",
    num: 4,
    link: "/Product Lines/StartupBrokers",
  },

  // {
  //   title:"Subsidiaries",
  //   menu:"Experiences",
  //   num:5,
  //   link:"/Subsidiaries/Experiences"
  // },
  // {
  //   title: "Subsidiaries",
  //   menu: "CryptoStartups",
  //   num: 5,
  //   link: "/Subsidiaries/CryptoStartups",
  // },
  // {
  //   title: "Subsidiaries",
  //   menu: "Spending",
  //   num: 6,
  //   link: "/Subsidiaries/Spending",
  // },
  // {
  //   title: "Subsidiaries",
  //   menu: "Businesses",
  //   link: "/Subsidiaries/businesses",
  //   num: 7,
  // },
];

const info = [
  {
    name: "Global X Change",
    img: gxsmalllogo,
  },
  {
    name: "INR Group",
    img: inrlogo,
  },
  {
    name: "Viral Group",
    img: virual,
  },
  {
    name: "Employment Group",
    img: employement,
  },
  {
    name: "Experiences Group",
    img: expirence,
  },
  {
    name: "",
    img: "",
  },
  {
    name: "",
    img: "",
  },
  {
    name: "",
    img: "",
  },
  {
    name: "",
    img: "",
  },
  {
    name: "",
    img: "",
  },
  {
    name: "",
    img: "",
  },
  {
    name: "",
    img: "",
  },
  {
    name: "",
    img: "",
  },
];

const NewHomePage = () => {
  let history = useHistory();
  const location = useLocation();
  const recogRef = useRef();
  const {
    nav,
    setnav,
    sideNav,
    setSideNav,
    collapsedSideBar,
    setCollapsedSideBar,
    navExpand,
    setNavExpand,
    setSubsidaryMenu,
    mobileScreensNav,
    setMobileScreensNav,
    setShowEach,
    gotoTop,
    setgotoTop,
  } = useContext(MainContext);
  const vidRef = useRef();

  useEffect(() => { vidRef.current.play(); }, []);
  const [mobsize, setmobsize] = useState(0);
  const [scrollup, setscrollup] = useState(0);
  const [foundName, setfoundName] = useState("");
  const [menunv, setmenunv] = useState("Story")
  const [otp, setOtp] = useState('');
  const [modelopen, setmodelopen] = useState(false)
  const [selectedtab, setselectedtab] = useState('')

  // useEffect(() => {
  //   history.push(`/`);
  // }, []);
  console.log("selectedtab", nav)
  useEffect(() => {
    if (selectedtab == "tracker") {
      if (otp == "4141") {

        history.push("/tracker");
      }
    }
    if (selectedtab == "hoilding") {
      if (otp == "4141") {

        history.push("/portfolio")
      }
    }
  }, [otp])
  const otpchange = (e) => {
    // let s=e
    setOtp(e)

  }

  useLayoutEffect(() => {
    function updateSize() {
      setmobsize(window.innerWidth);
      if (window.innerWidth <= 900) {
        setCollapsedSideBar(false);
        setSideNav(99);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      setmobsize(window.innerWidth);
      if (window.innerWidth > 900) {
        setCollapsedSideBar(false);
        setSideNav(0)
        // if(sideNav === ""){
        //   
        // }
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [mobsize]);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const handleSidebar = (each, value, item) => {

    setnav(each.title);
    setmenunv(item)
    if (value === "") {
      if (each.title === "About" && value === "") {
        // setnav(each.title);
        // setSideNav(0);
        //  alert()
        history.push(`/${each.title.toLowerCase()}/story`);
        // var element = document.getElementById("menu");
        // element.scrollIntoView({ behavior: "smooth" });
      } else {
        // setnav(each.title);
        // setSideNav(value);
        // var element = document.getElementById("menu");
        // element.scrollIntoView({ behavior: "smooth" });
        history.push(`/${each.title.toLowerCase()}`);
      }
    } else {
      history.push(`/${each.title.toLowerCase()}/${item.toLowerCase()}`);
    }

    // if(title === "About" && value === ""){
    //   setnav(title);
    //   setSideNav(0);
    // }else{
    //   setnav(title);
    //   if(value !== 8){
    //     setSideNav(value);
    //     setSubsidaryMenu(0);
    //     var element = document.getElementById("menu");

    //     element.scrollIntoView({ behavior: "smooth" });
    //   }
    // }
    // setShowEach("")
  };

  const handleMove = () => {
    setnav("About");
    setSideNav(0);
    var element = document.getElementById("menu");

    element.scrollIntoView({ behavior: "smooth" });
  };

  console.log("nahhdjhjdfjhv", mobileScreensNav, sideNav);

  const handleScroll = (e) => {
    // console.log(e)
    setscrollup(e.target.scrollTop);

    if (e.target.scrollTop > 0) {
      if (window.innerWidth > 900) {
        if (nav === "" && window.location.pathname === "/") {
          setnav("About");
          setSideNav(0);
        }
      }
    }

    if (e.target.scrollTop >= 10) {
      setNavExpand(true);
      // if(nav === ""){
      //   setnav("About")
      // }
    } else {
      setNavExpand(false);
      setgotoTop(false);

    }

    // console.log(navExpand)
  };

  useEffect(() => {
    // let pathValue = window.location.pathname.substring(1,)
    let result = allmenus.filter((item, k) => {
      return item.link.toLowerCase() === window.location.pathname.toLowerCase();
    });
    console.log("xvcxcvxcv", window.location.pathname.toLowerCase());
    if (result.length > 0) {
      handleChange(result);
    }
    // if (window.location.pathname === "/") {
    //   handleChange(result);
    // }


  }, [window.location.pathname]);



  const handleChange = (result) => {

    // alert()
    if (window.innerWidth > 900) {
      if (window.location.pathname.toLowerCase() === "/holidings/enterprise") {
        return
      }
      else if (window.location.pathname.toLowerCase() === "/holidings/consumer") {
        return
      }
      else if (window.location.pathname.toLowerCase() === "/holidings/offline") {
        return
      }
      else if (window.location.pathname.toLowerCase() === "/about/story" || window.location.pathname.toLowerCase() === "/about/founders" ||
        window.location.pathname.toLowerCase() === "/about/executives" || window.location.pathname.toLowerCase() === "/about/investors" ||
        window.location.pathname.toLowerCase() === "/about/footprint" || window.location.pathname.toLowerCase() === "/about/advisors" ||
        window.location.pathname.toLowerCase() === "/about/roadmap"
      ) {
        // alert()
        var element = document.getElementById("menu");
        element?.scrollIntoView({ behavior: "smooth" });
        setnav(result[0].title);
        scrollToMenu(recogRef);
        // return
      }
      else {
        setnav(result[0].title);
      }
      // if(/holidings/enterprise)

    }

    setMobileScreensNav(result[0].title);
    setSideNav(result[0].num);

    // scrollToMenu(recogRef);
  };

  const scrollToMenu = (recogRef) => {
    setImmediate(() =>
      recogRef.current.scrollIntoView({ inline: "center", behavior: "smooth" })
    );
  };

  console.log("nzxczcxz ", nav, sideNav);
  useEffect(() => {
    history.push("/")
  }, [])
  return (
    <>
      <div style={{ position: "relative" }}>
        {/* <div> */}
        <Navbar
          setmodelopen={setmodelopen}
          setselectedtab={setselectedtab}
        />
        {/* </div> */}
        <div
          style={{ height: gotoTop ? "auto" : "100vh", overflowY: "scroll" }}
          onScroll={(e) => handleScroll(e)}
        >


          <div className="home-sec1" id="home">
            <div className="home-sec1-part1">
              <div className="home-sec1-part1-left">
                <div className="home-sec1-part1-title">
                  The World’s First Ai Powered
                </div>
                <div className="home-sec1-part1-subtitle"
                  style={{
                    fontWeight: "600"
                  }}
                >
                  Healthcare Ecosystem
                </div>
                <div className="home-sec1-part1-desc">

                  Mediverse helps insurance, healthcare and wellness providers transform into an Ai first business by leveraging their patient data.

                </div>
                <div className="home-sec1-part1-mob-desc">
                  Mediverse helps insurance, healthcare and wellness providers transform into an Ai first business by leveraging their patient data.
                </div>
                <div className="home-sec1-part1-buttons"
                  style={{
                    gap: "1rem"
                  }}
                >
                  <div className="home-sec1-part1-btn2"
                  // onClick={() => window.open("http://indianinvestor.com/", "_blank")}
                  >Learn More</div>
                  <div className="home-sec1-part1-btn2"
                    style={{
                      background: "#EFEE0E",
                      color: "#5E3199"
                    }}
                  // onClick={() => window.open("http://indianinvestor.com/", "_blank")}
                  >Contact Us</div>
                </div>
              </div>
              <div className="home-sec1-part1-right">
                <div className="imagesBox">
                  <div className="rowbox">
                    <div className="eachcirclebox"
                      onClick={() => window.open("http://marketsverse.com/", "_blank")}
                    >
                      <img className="eachimgcircle" src={img1} alt="" />
                    </div>
                    <div className="eachhorzbox"

                    >
                      <img className="eachlineh" src={horzline} alt="" />
                    </div>
                    <div className="eachcirclebox"
                      onClick={() => window.open("http://viral.group/", "_blank")}
                    >
                      <img className="eachimgcircle" src={img2} alt="" />
                    </div>
                    <div className="eachhorzbox">
                      <img className="eachlineh" src={horzline} alt="" />
                    </div>
                    <div className="eachcirclebox"
                      onClick={() => window.open("http://startupbrokers.com/", "_blank")}
                    >
                      <img className="eachimgcircle" src={img3} alt="" />
                    </div>
                  </div>
                  <div className="linebox">
                    <div className="eachvertbox">
                      <img className="eachlinev" src={vertline} alt="" />
                    </div>
                    <div className="linesgap"> </div>
                    <div className="eachvertbox">
                      <img className="eachlinev" src={vertline} alt="" />
                    </div>
                    <div className="linesgap"> </div>
                    <div className="eachvertbox">
                      <img className="eachlinev" src={vertline} alt="" />
                    </div>
                  </div>
                  <div className="rowbox">
                    <div className="eachcirclebox"
                      onClick={() => window.open("http://innovationgarage.in/", "_blank")}
                    >
                      <img className="eachimgcircle" src={img4} alt="" />
                    </div>
                    <div className="eachhorzbox">
                      <img className="eachlineh" src={horzline} alt="" />
                    </div>
                    <div className="eachcirclebox">
                      <img className="eachimgcircle" src={img5} alt="" />
                    </div>
                    <div className="eachhorzbox">
                      <img className="eachlineh" src={horzline} alt="" />
                    </div>
                    <div className="eachcirclebox"
                      onClick={() => window.open("http://airevolution.app/", "_blank")}
                    >
                      <img className="eachimgcircle" src={img6} alt="" />
                    </div>
                  </div>
                  <div className="linebox">
                    <div className="eachvertbox">
                      <img className="eachlinev" src={vertline} alt="" />
                    </div>
                    <div className="linesgap"> </div>
                    <div className="eachvertbox">
                      <img className="eachlinev" src={vertline} alt="" />
                    </div>
                    <div className="linesgap"> </div>
                    <div className="eachvertbox">
                      <img className="eachlinev" src={vertline} alt="" />
                    </div>
                  </div>
                  <div className="rowbox">
                    <div className="eachcirclebox"
                      onClick={() => window.open("http://gravitichain.com/", "_blank")}
                    >
                      <img className="eachimgcircle" src={img7} alt="" />
                    </div>
                    <div className="eachhorzbox">
                      <img className="eachlineh" src={horzline} alt="" />
                    </div>
                    <div className="eachcirclebox"
                      onClick={() => window.open("http://indianinvestor.com/", "_blank")}
                    >
                      <img className="eachimgcircle" src={img8} alt="" />
                    </div>
                    <div className="eachhorzbox">
                      <img className="eachlineh" src={horzline} alt="" />
                    </div>
                    <div className="eachcirclebox"
                      onClick={() => window.open("http://employment.group/", "_blank")}
                    >
                      <img className="eachimgcircle" src={img9} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="secondpartMian">
              <div className="home-sec1-part2">
                {sec1Boxes.map((each, i) => {
                  return (
                    <div className="home-sec1-part2-each" key={i}>
                      <div className="home-sec1-part2-title">{each.title}</div>
                      <div className="home-sec1-part2-desc">
                        {each.desc}
                        <br />
                        {each.desc1}
                      </div>
                      <div className="innerzoomtext">
                        <div className="zoom1" style={{ cursor: "pointer" }}

                        >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                              height: "40px",
                              background: "#EFEE0E"

                            }}
                            onClick={() => each.title == "SuperNova" && history.push("/supernova")}
                          >
                            <div className="check1-text"
                              style={{
                                color: "#5E3199"
                              }}
                            >Apply</div>
                            <img
                              className="clickarrow-img"
                              src={clickarrow}
                              alt=""
                            />
                          </span>
                        </div>
                        <div className="textyearly">{each.year}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

          </div>


          <div className="thridvidePlay">
            <div className="leftsie">
              <video
                src={vidofile}
                ref={vidRef}
                muted
                autoPlay
                loop
              />
            </div>
            <div className="playerData">

              <div className="rightside">
                <img src={axiosimg} />
                <div className="textdata">Mediverse is a health-tech holdings company that develops and operates platforms which offer tools to healthcare and wellness providers.</div>
              </div>
            </div>
          </div>
          <Landing />

          <div className="holdingPadmobile">
            <Hoilding
              setmodelopen={setmodelopen}
              setselectedtab={setselectedtab}
            />
          </div>



          <div className="mobile-screens">
            {mobileScreensNav === "About" && sideNav === 99 ? (
              <>
                <div className="home-sec1-mobile" id="home">
                  <div className="home-sec1-part1">
                    <div className="home-sec1-part1-left">
                      <div className="home-sec1-part1-title">The World’s First Ai Powered</div>
                      <div className="home-sec1-part1-subtitle">
                        Healthcare Ecosystem
                      </div>
                      <div className="home-sec1-part1-desc">
                        Mediverse is a health-tech holdings company that develops and
                        operates platforms which offer tools to healthcare and wellness
                        <br />
                        <br />
                        providers.
                      </div>
                      <div className="home-sec1-part1-mob-desc">
                        One-stop solution for early-stage startups, that provides an ecosystem to anyone with a viable idea to become the next revolution.
                      </div>
                      <div className="home-sec1-part1-buttons">
                        <div
                          className="home-sec1-part1-btn1"
                          onClick={() => window.open("http://innovationgarage.in/", "_blank")}
                        >
                          Raise Capital
                        </div>
                        <div
                          className="home-sec1-part1-btn2"
                          onClick={() => window.open("http://indianinvestor.com/", "_blank")}
                        >
                          For Investors
                        </div>
                      </div>
                    </div>
                    <div className="home-sec1-part1-right">
                      <div className="imagesBox">
                        <div className="rowbox">
                          <div className="eachcirclebox"
                            onClick={() => window.open("http://marketsverse.com/", "_blank")}
                          >
                            <img className="eachimgcircle" src={img1} alt="" />
                          </div>
                          <div className="eachhorzbox"

                          >
                            <img className="eachlineh" src={horzline} alt="" />
                          </div>
                          <div className="eachcirclebox"
                            onClick={() => window.open("http://viral.group/", "_blank")}
                          >
                            <img className="eachimgcircle" src={img2} alt="" />
                          </div>
                          <div className="eachhorzbox">
                            <img className="eachlineh" src={horzline} alt="" />
                          </div>
                          <div className="eachcirclebox"
                            onClick={() => window.open("http://startupbrokers.com/", "_blank")}
                          >
                            <img className="eachimgcircle" src={img3} alt="" />
                          </div>
                        </div>
                        <div className="linebox">
                          <div className="eachvertbox">
                            <img className="eachlinev" src={vertline} alt="" />
                          </div>
                          <div className="linesgap"> </div>
                          <div className="eachvertbox">
                            <img className="eachlinev" src={vertline} alt="" />
                          </div>
                          <div className="linesgap"> </div>
                          <div className="eachvertbox">
                            <img className="eachlinev" src={vertline} alt="" />
                          </div>
                        </div>
                        <div className="rowbox">
                          <div className="eachcirclebox"
                            onClick={() => window.open("http://innovationgarage.in/", "_blank")}
                          >
                            <img className="eachimgcircle" src={img4} alt="" />
                          </div>
                          <div className="eachhorzbox">
                            <img className="eachlineh" src={horzline} alt="" />
                          </div>
                          <div>
                            <img className="eachimgcircle1" src={img5} alt="" />
                          </div>
                          <div className="eachhorzbox">
                            <img className="eachlineh" src={horzline} alt="" />
                          </div>
                          <div className="eachcirclebox"
                            onClick={() => window.open("http://airevolution.app/", "_blank")}
                          >
                            <img className="eachimgcircle" src={img6} alt="" />
                          </div>
                        </div>
                        <div className="linebox">
                          <div className="eachvertbox">
                            <img className="eachlinev" src={vertline} alt="" />
                          </div>
                          <div className="linesgap"> </div>
                          <div className="eachvertbox">
                            <img className="eachlinev" src={vertline} alt="" />
                          </div>
                          <div className="linesgap"> </div>
                          <div className="eachvertbox">
                            <img className="eachlinev" src={vertline} alt="" />
                          </div>
                        </div>
                        <div className="rowbox">
                          <div className="eachcirclebox"
                            onClick={() => window.open("http://gravitichain.com/", "_blank")}
                          >
                            <img className="eachimgcircle" src={img7} alt="" />
                          </div>
                          <div className="eachhorzbox">
                            <img className="eachlineh" src={horzline} alt="" />
                          </div>
                          <div className="eachcirclebox"
                            onClick={() => window.open("http://indianinvestor.com/", "_blank")}
                          >
                            <img className="eachimgcircle" src={img8} alt="" />
                          </div>
                          <div className="eachhorzbox">
                            <img className="eachlineh" src={horzline} alt="" />
                          </div>
                          <div className="eachcirclebox"
                            onClick={() => window.open("http://employment.group/", "_blank")}
                          >
                            <img className="eachimgcircle" src={img9} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="home-sec1-part2">
                    {sec1Boxes.map((each, i) => {
                      return (
                        <div className="home-sec1-part2-each" key={i}>
                          <div className="home-sec1-part2-title">{each.title}</div>
                          <div className="home-sec1-part2-desc">
                            {each.desc}
                            <br />
                            {each.desc1}
                          </div>
                          <div className="innerzoomtext">
                            <div className="zoom1" style={{
                              cursor: "pointer",
                              height: "40px",

                              width: "120px"

                            }}

                              onClick={() => each?.title == "SuperNova" && history.push("/supernova")}
                            >
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                  height: "40px",
                                  opacity: each?.title == "SuperNova" ? 1 : 0.5
                                }}
                              >
                                <div className="check1-text"
                                  style={{
                                    display: "flex",
                                    background: "linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%)"
                                  }}
                                >Explore</div>
                                <img
                                  className="clickarrow-img"
                                  src={clickarrow}
                                  alt=""
                                />
                              </span>

                            </div>
                            <div className="textyearly">{each.year}</div>

                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <MobileHading
                  setmodelopen={setmodelopen}
                  setselectedtab={setselectedtab}
                />

                {foundName == "Shorupan" ? (
                  <div className="founder-main">
                    <div className="founder-left">
                      <div className="founder-title">
                        Shorupan’s Story{" "}
                        <div
                          className="back"
                          onClick={() => {
                            setfoundName("");
                            // history.push("/about/founders");
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div className="founder-subtitle">Founder & CTO </div>
                      <div className="founder-desc">
                        Shorupan is a sophisticated blockchain investor and a
                        sought after subject matter specialist for
                        enterprise-level blockchain integration. With a
                        background in structured portfolio management, Shorupan
                        has consulted and scaled traditional financial firms
                        whose specializations range from the foreign exchange
                        markets to insurance derivatives.
                        <br />
                        <br />
                        His exposure to the cryptocurrency markets came during
                        his tenure as Chief Investment Officer at Toronto based
                        VC firm, Generation Z International. There he
                        constructed one of Canada's first blockchain investment
                        funds which monetized off the market boom of 2016-2017.
                        Since then Shorupan has become a thought leader in the
                        space, engaging with corporations and universities to
                        help elucidate the business implications of the
                        blockchain.
                        <br />
                        <br />
                        In 2018 he Co-Founded Nvest Global Enterprises and
                        launched Canada's first retail cryptocurrency financial
                        institution. Now he is scaling NGE to become a global
                        provider of blockchain based banking systems and manages
                        operations in over six countries.
                      </div>
                    </div>
                    <div className="founder-right">
                      <img className="founder-img" src={founderimg} alt="" />
                    </div>
                  </div>
                ) : (
                  <Founders
                    setfoundName={setfoundName}
                  />

                )}

                <div className="founderCardSection">
                  <h5>Our Founders</h5>
                  {/* <div
                   className="cardsinnerFounder"
                   onClick={() => {
                     setfoundName("Shorupan");
                     history.push("about/team/Shorupan");
                   }}
                 >
                   <div>
                     <h1>Shorupan P</h1>
                     <p>Chief Technology Officer</p>
                   </div>
                   <img src={shorupn} />
                 </div> */}

                  <div className="cardsinnerFounder">
                    <div>
                      <div className="imgcirle"></div>
                      <h1>Vivek G</h1>
                      <p>Investor Relations</p>
                    </div>
                  </div>

                  <div className="cardsinnerFounder">
                    <div>
                      <div className="imgcirle"></div>
                      <h1>Roheel J</h1>
                      <p>Head Of Intelligence</p>
                    </div>
                  </div>
                  <div className="cardsinnerFounder">
                    <div>
                      <div className="imgcirle"></div>
                      <h1>Siva G</h1>
                      <p>Head Of Finance</p>
                    </div>
                  </div>

                  <div className="cardsinnerFounder">
                    <div>
                      <div className="imgcirle"></div>
                      <h1>Abhinav A</h1>
                      <p>Lead Analyst</p>
                    </div>
                  </div>


                  <div className="cardsinnerFounder">
                    <div>
                      <div className="imgcirle"></div>
                      <h1>Betty T</h1>
                      <p>Human Resources</p>
                    </div>
                  </div>
                  <div className="cardsinnerFounder">
                    <div>
                      <div className="imgcirle"></div>
                      <h1>Abhishek S</h1>
                      <p>Portfolio Manager</p>
                    </div>
                  </div>
                </div>


                <div className="founderCardSection">


                  <h5>Our Investors</h5>
                  <div className="cardsinnerFounder"
                    style={{
                      width: "100%",
                      display: 'flex',
                      justifyContent: 'space-between',
                      borderRadius: "15px",
                      border: "1px solid #EDEDED",
                      height: "14rem",
                      paddingLeft: "3%"



                    }}
                  >
                    <div>
                      <h1
                        style={{
                          textAlign: "left",
                        }}
                      >Nvest Group</h1>
                      <p
                        style={{
                          textAlign: "left",
                        }}
                      >Fintech Investor</p>
                    </div>
                    <img src={nvest}
                      style={{
                        position: "static",
                        height: "auto"
                      }}
                    />
                  </div>
                </div>




                <div className="founderCardSection">


                  <h5>Our Advisors</h5>
                  {/* <div className="cardsinnerFounder"
style={{
width: "100%",
display:'flex',
justifyContent:'space-between',
borderRadius: "15px",
border: "1px solid #EDEDED",
height:"14rem",
paddingLeft:"3%"



}}
>
<div>
<h1
style={{
textAlign: "left",
}}
>Tim Cook</h1>
<p
style={{
textAlign: "left",
}}
>CEO At Apple</p>
</div>
<img src={cock}
style={{
position: "static",
height:"auto"
}}
/>
</div> */}
                </div>

              </>
            ) : mobileScreensNav === "About" && sideNav === 0 ? (

              <TimeLine />

            ) : mobileScreensNav === "About" && sideNav === 1 ? (
              // <div
              //   className="full"
              //   style={{
              //     backgroundImage: `url(${time2})`,
              //     backgroundSize: "cover",
              //   }}
              // >
              //   <div className="new-data-box sec2">
              //     <h1 className="new-data-box-title">
              //       The Nvest Group Was Founded By
              //     </h1>
              //     <h3 className="new-data-box-main">
              //       Shorupan Pirakaspathy
              //     </h3>
              //   </div>

              //   <h6 className="new-data-box-button sec2-button">
              //     //&nbsp;<span>Read Bio</span>&nbsp;//
              //   </h6>
              // </div>

              // <>
              //  <div className="founderCardSection">
              //   <div className="cardsinnerFounder">
              //     <div>
              //   <h1>Philip T</h1>
              //   <p>Chief Executive Officer</p>

              //     </div>
              //   </div>
              // </div>
              // </>

              <>
                {foundName == "Shorupan" ? (
                  <div className="founder-main">
                    <div className="founder-left">
                      <div className="founder-title">
                        Shorupan’s Story{" "}
                        <div
                          className="back"
                          onClick={() => {
                            setfoundName("");
                            history.push("about/Executives");
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div className="founder-subtitle">Founder & CTO </div>
                      <div className="founder-desc">
                        Shorupan is a sophisticated blockchain investor and a
                        sought after subject matter specialist for
                        enterprise-level blockchain integration. With a
                        background in structured portfolio management, Shorupan
                        has consulted and scaled traditional financial firms
                        whose specializations range from the foreign exchange
                        markets to insurance derivatives.
                        <br />
                        <br />
                        His exposure to the cryptocurrency markets came during
                        his tenure as Chief Investment Officer at Toronto based
                        VC firm, Generation Z International. There he
                        constructed one of Canada's first blockchain investment
                        funds which monetized off the market boom of 2016-2017.
                        Since then Shorupan has become a thought leader in the
                        space, engaging with corporations and universities to
                        help elucidate the business implications of the
                        blockchain.
                        <br />
                        <br />
                        In 2018 he Co-Founded Nvest Global Enterprises and
                        launched Canada's first retail cryptocurrency financial
                        institution. Now he is scaling NGE to become a global
                        provider of blockchain based banking systems and manages
                        operations in over six countries.
                      </div>
                    </div>
                    <div className="founder-right">
                      <img className="founder-img" src={founderimg} alt="" />
                    </div>
                  </div>
                ) : (
                  <div className="founderCardSection">

                    {/* <div
                      className="cardsinnerFounder"
                      onClick={() => {
                        setfoundName("Shorupan");
                        history.push("about/Executives/Shorupan");
                      }}
                    >
                      <div>
                        <h1>Shorupan P</h1>
                        <p>Chief Technology Officer</p>
                      </div>
                      <img src={shorupn} />
                    </div> */}
                    <div className="cardsinnerFounder">
                      <div>
                        <h1>Siva G</h1>
                        <p>Head Of Finance</p>
                      </div>
                    </div>
                    <div className="cardsinnerFounder">
                      <div>
                        <h1>Vivek G</h1>
                        <p>Investor Relations</p>
                      </div>
                    </div>

                    <div className="cardsinnerFounder">
                      <div>
                        <h1>Roheel J</h1>
                        <p>Head Of Intelligence</p>
                      </div>
                    </div>

                    <div className="cardsinnerFounder">
                      <div>
                        <h1>Abhishek S</h1>
                        <p>Portfolio Manager</p>
                      </div>
                    </div>

                    <div className="cardsinnerFounder">
                      <div>
                        <h1>Abhinav A</h1>
                        <p>Lead Analyst</p>
                      </div>
                    </div>
                    <div className="cardsinnerFounder">
                      <div>
                        <h1>Betty T</h1>
                        <p>Human Resources</p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : mobileScreensNav === "About" && sideNav === 2 ? (
              <Googlemap id="Footprint" key="3" />
            ) : mobileScreensNav === "holidings" && sideNav === 33 ?
              (
                <Hoilding />
              ) : mobileScreensNav === "media" && sideNav === 44 ? (
                <>
                  <Media />
                </>
              ) :
                mobileScreensNav === "media" && sideNav === 55 ? (
                  <>
                    <ContactUs />
                  </>
                ) : mobileScreensNav === "About" && sideNav === 3 ? (
                  <div className="roadMap">
                    <div
                      className="custom-scroll-scroll "
                      id="Our Roadmap"
                      key="4"
                    ></div>
                    <div className="road-map-content">
                      <h1>Our Roadmap</h1>
                      {/* <div className="date-raodmap">
                  <img src={leftarrow} />
                  <h2>2023</h2>
                  <img src={right} />
                </div> */}
                    </div>

                    <div className="maintable">
                      <table className="wholetable">
                        <thead>
                          <tr>
                            <th>
                              <div className="columnheadempty"></div>
                            </th>
                            <th>
                              <div className="columnhead">Jan</div>
                            </th>
                            <th>
                              <div className="columnhead">Feb</div>
                            </th>
                            <th>
                              <div className="columnhead">Mar</div>
                            </th>
                            <th>
                              <div className="columnhead">April</div>
                            </th>
                            <th>
                              <div className="columnhead">May</div>
                            </th>
                            <th>
                              <div className="columnhead">Jun</div>
                            </th>
                            <th>
                              <div className="columnhead">Jul</div>
                            </th>
                            <th>
                              <div className="columnhead">Aug</div>
                            </th>
                            <th>
                              <div className="columnhead">Sep</div>
                            </th>
                            <th>
                              <div className="columnhead">Oct</div>
                            </th>
                            <th>
                              <div className="columnhead">Nov</div>
                            </th>
                            <th>
                              <div className="columnhead">Dec</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {info.map((single, i) => {
                            return (
                              <tr>
                                <th>
                                  <div className="eachtablesidemenu">
                                    <img
                                      className="tablemenuimg"
                                      src={single.img}
                                      alt=""
                                    />
                                    <div className="tablemenutitle">
                                      {single.name}
                                    </div>
                                  </div>
                                </th>
                                <>
                                  {info.map((one, j) => {
                                    return (
                                      <td>
                                        <div className="tabmenubox1">
                                          <div></div>
                                        </div>
                                      </td>
                                    );
                                  })}
                                </>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : mobileScreensNav === "Subsidiaries" && sideNav === "" ? (
                  <Subsidiaries />
                ) : mobileScreensNav === "Subsidiaries" && sideNav !== "" ? (
                  <SubSubsidiaries />
                ) : mobileScreensNav === "Development" ? (
                  <div
                    style={{
                      height: "calc(100vh - 76px)",
                      width: "90vw",
                      margin: "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "700",
                      fontSize: "6vw",
                    }}
                  >
                    Coming Soon..!
                  </div>
                ) : (
                  ""
                )}
          </div>
          <div className="home-sec2" id="menu" ref={recogRef}>
            <div className="home-sec2-left">
              {sideBar.map((each, i) => {
                return (
                  // <div className={sideNav === i ? 'menu-selected':'each-side-menu'} onClick={() => setSideNav(i)}>{each}</div>
                  <div className="all-side-menus">
                    {/* <div
                    className="menu-title"
                    onClick={() => handleSidebar(each, "", "")}
                    style={{
                      borderRadius: nav == item && "0px 35px 35px 0px",
                      background:
                        nav == each?.title &&
                        "linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%)",
                      width: nav == each?.title && "90%",
                      color: nav == each?.title && "white",
                    }}
                  >
                    {each.title}
                  </div> */}
                    <div class="sub-menu-box">
                      {each.subMenu.map((item, j) => {
                        console.log("navxzc", nav)
                        return (
                          <div
                            className={
                              each.title === nav && sideNav === j
                                ? "menu-selected"
                                : "each-side-menu"
                            }
                            onClick={() =>
                              j !== 9 ? handleSidebar(each, j, item) : ""
                            }
                            // style={{
                            //   cursor: j === 9 ? "not-allowed" : "pointer",
                            // }}
                            style={{
                              borderRadius: menunv == item && "0px 35px 35px 0px",
                              background:
                                menunv == item &&
                                "linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%)",
                              width: menunv == item && "90%",
                              color: menunv == item && "white",
                              //   opacity:item  =="Philanthropy"   ?  "0.4" :"1",
                              // cursor:item  =="Philanthropy"   ?  "not-allowed" :"pointer",
                              pointerEvents: item == "Philanthropy" ? "none" : "pointer"
                            }}
                          >
                            {item}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="home-sec2-right">
              {nav === "About" && sideNav === 0 ? (
                <>

                  {foundName == "Shorupan" ? (
                    <div className="founder-main">
                      <div className="founder-left">
                        <div className="founder-title">
                          Shorupan’s Story{" "}
                          <div
                            className="back"
                            onClick={() => {
                              setfoundName("");
                              history.push("/about/founders");
                            }}
                          >
                            Back
                          </div>
                        </div>
                        <div className="founder-subtitle">Founder & CTO </div>
                        <div className="founder-desc">
                          Shorupan is a sophisticated blockchain investor and a
                          sought after subject matter specialist for
                          enterprise-level blockchain integration. With a
                          background in structured portfolio management, Shorupan
                          has consulted and scaled traditional financial firms
                          whose specializations range from the foreign exchange
                          markets to insurance derivatives.
                          <br />
                          <br />
                          His exposure to the cryptocurrency markets came during
                          his tenure as Chief Investment Officer at Toronto based
                          VC firm, Generation Z International. There he
                          constructed one of Canada's first blockchain investment
                          funds which monetized off the market boom of 2016-2017.
                          Since then Shorupan has become a thought leader in the
                          space, engaging with corporations and universities to
                          help elucidate the business implications of the
                          blockchain.
                          <br />
                          <br />
                          In 2018 he Co-Founded Nvest Global Enterprises and
                          launched Canada's first retail cryptocurrency financial
                          institution. Now he is scaling NGE to become a global
                          provider of blockchain based banking systems and manages
                          operations in over six countries.
                        </div>
                      </div>
                      <div className="founder-right">
                        <img className="founder-img" src={founderimg} alt="" />
                      </div>
                    </div>
                  ) : (
                    <Founders
                      setfoundName={setfoundName}
                    />

                  )}
                </>
              ) : nav === "About" && sideNav === 199 ? (

                <>

                  {foundName == "Shorupan" ? (
                    <div className="founder-main">
                      <div className="founder-left">
                        <div className="founder-title">
                          Shorupan’s Story{" "}
                          <div
                            className="back"
                            onClick={() => {
                              setfoundName("");
                              history.push("/about/founders");
                            }}
                          >
                            Back
                          </div>
                        </div>
                        <div className="founder-subtitle">Founder & CTO </div>
                        <div className="founder-desc">
                          Shorupan is a sophisticated blockchain investor and a
                          sought after subject matter specialist for
                          enterprise-level blockchain integration. With a
                          background in structured portfolio management, Shorupan
                          has consulted and scaled traditional financial firms
                          whose specializations range from the foreign exchange
                          markets to insurance derivatives.
                          <br />
                          <br />
                          His exposure to the cryptocurrency markets came during
                          his tenure as Chief Investment Officer at Toronto based
                          VC firm, Generation Z International. There he
                          constructed one of Canada's first blockchain investment
                          funds which monetized off the market boom of 2016-2017.
                          Since then Shorupan has become a thought leader in the
                          space, engaging with corporations and universities to
                          help elucidate the business implications of the
                          blockchain.
                          <br />
                          <br />
                          In 2018 he Co-Founded Nvest Global Enterprises and
                          launched Canada's first retail cryptocurrency financial
                          institution. Now he is scaling NGE to become a global
                          provider of blockchain based banking systems and manages
                          operations in over six countries.
                        </div>
                      </div>
                      <div className="founder-right">
                        <img className="founder-img" src={founderimg} alt="" />
                      </div>
                    </div>
                  ) : (
                    <Founders
                      setfoundName={setfoundName}
                    />

                  )}
                </>

              ) : nav === "About" && sideNav === 788 ? (
                <div className="founderCardSection">



                  <div className="cardsinnerFounder">
                    <div>
                      <h1>Nvest Group</h1>
                      <p>Fintech Investor</p>
                    </div>
                    <img src={nvest} />
                  </div>
                </div>

              ) : nav === "About" && sideNav === 88 ? (
                <div className="founderCardSection">

                  {/* <div
                className="cardsinnerFounder"
                onClick={() => {
                  setfoundName("Shorupan");
                  history.push("about/team/Shorupan");
                }}
              >
                <div>
                  <h1>Shorupan P</h1>
                  <p>Chief Technology Officer</p>
                </div>
                <img src={shorupn} />
              </div> */}
                  {/* <div className="cardsinnerFounder">
                <div>
                  <h1>Tim Cook</h1>
                  <p>CEO At Apple</p>
                </div>
                <img src={cock}/>
              </div> */}


                  {/* <div className="cardsinnerFounder">
                <div>
                  <h1>Roheel J</h1>
                  <p>Head Of Intelligence</p>
                </div>
              </div>
              <div className="cardsinnerFounder">
                <div>
                  <h1>Siva G</h1>
                  <p>Head Of Finance</p>
                </div>
              </div>

              <div className="cardsinnerFounder">
                <div>
                  <h1>Abhinav A</h1>
                  <p>Lead Analyst</p>
                </div>
              </div>

            
              <div className="cardsinnerFounder">
                <div>
                  <h1>Betty T</h1>
                  <p>Human Resources</p>
                </div>
              </div>
              <div className="cardsinnerFounder">
                <div>
                  <h1>Abhishek S</h1>
                  <p>Portfolio Manager</p>
                </div>
              </div> */}
                </div>
              ) : nav === "About" && sideNav === 1 ? (
                // <div
                //   className="full"
                //   style={{
                //     backgroundImage: `url(${time2})`,
                //     backgroundSize: "cover",
                //   }}
                // >
                //   <div className="new-data-box sec2">
                //     <h1 className="new-data-box-title">
                //       The Nvest Group Was Founded By
                //     </h1>
                //     <h3 className="new-data-box-main">
                //       Shorupan Pirakaspathy
                //     </h3>
                //   </div>

                //   <h6 className="new-data-box-button sec2-button">
                //     //&nbsp;<span>Read Bio</span>&nbsp;//
                //   </h6>
                // </div>

                <>


                  <div className="founderCardSection">

                    {/* <div
                      className="cardsinnerFounder"
                      onClick={() => {
                        setfoundName("Shorupan");
                        history.push("about/team/Shorupan");
                      }}
                    >
                      <div>
                        <h1>Shorupan P</h1>
                        <p>Chief Technology Officer</p>
                      </div>
                      <img src={shorupn} />
                    </div> */}
                    <div className="cardsinnerFounder">
                      <div>
                        <h1>Siva G</h1>
                        <p>Head Of Finance</p>
                      </div>
                    </div>
                    <div className="cardsinnerFounder">
                      <div>
                        <h1>Vivek G</h1>
                        <p>Investor Relations</p>
                      </div>
                    </div>

                    <div className="cardsinnerFounder">
                      <div>
                        <h1>Roheel J</h1>
                        <p>Head Of Intelligence</p>
                      </div>
                    </div>

                    <div className="cardsinnerFounder">
                      <div>
                        <h1>Abhishek S</h1>
                        <p>Portfolio Manager</p>
                      </div>
                    </div>

                    <div className="cardsinnerFounder">
                      <div>
                        <h1>Abhinav A</h1>
                        <p>Lead Analyst</p>
                      </div>
                    </div>
                    <div className="cardsinnerFounder">
                      <div>
                        <h1>Betty T</h1>
                        <p>Human Resources</p>
                      </div>
                    </div>
                  </div>

                </>
              ) : nav === "About" && sideNav === 2 ? (
                <Googlemap id="Footprint" key="3" />
              ) : nav === "About" && sideNav === 3 ? (
                <div className="roadMap">
                  <div
                    className="custom-scroll-scroll "
                    id="Our Roadmap"
                    key="4"
                  ></div>
                  <div className="road-map-content">
                    <h1>Our Roadmap</h1>
                    {/* <div className="date-raodmap">
                    <img src={leftarrow} />
                    <h2>2022</h2>
                    <img src={right} />
                  </div> */}
                  </div>

                  <div className="maintable">
                    <table className="wholetable">
                      <thead>
                        <tr>
                          <th>
                            <div className="columnheadempty"></div>
                          </th>
                          <th>
                            <div className="columnhead">Jan</div>
                          </th>
                          <th>
                            <div className="columnhead">Feb</div>
                          </th>
                          <th>
                            <div className="columnhead">Mar</div>
                          </th>
                          <th>
                            <div className="columnhead">April</div>
                          </th>
                          <th>
                            <div className="columnhead">May</div>
                          </th>
                          <th>
                            <div className="columnhead">Jun</div>
                          </th>
                          <th>
                            <div className="columnhead">Jul</div>
                          </th>
                          <th>
                            <div className="columnhead">Aug</div>
                          </th>
                          <th>
                            <div className="columnhead">Sep</div>
                          </th>
                          <th>
                            <div className="columnhead">Oct</div>
                          </th>
                          <th>
                            <div className="columnhead">Nov</div>
                          </th>
                          <th>
                            <div className="columnhead">Dec</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {info.map((single, i) => {
                          return (
                            <tr>
                              <th>
                                <div className="eachtablesidemenu">
                                  {
                                    single.img &&
                                    <>
                                      <img
                                        className="tablemenuimg"
                                        src={single.img}
                                        alt=""
                                      />
                                      <div className="tablemenutitle">
                                        {single.name}
                                      </div>
                                    </>
                                  }
                                </div>
                              </th>
                              <>
                                {info.map((one, j) => {
                                  return (
                                    <td>
                                      <div className="tabmenubox1">
                                        <div></div>
                                      </div>
                                    </td>
                                  );
                                })}
                              </>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : nav === "Subsidiaries" && sideNav === "" ? (
                <Subsidiaries />
              ) : nav === "Subsidiaries" && sideNav !== "" ? (
                <SubSubsidiaries />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="holdingPadmobile">
            <Media />
          </div>
          <div className="holdingPadmobile">
            <ContactUs /></div>

          <Footer />

        </div>

        <div
          style={{
            display: collapsedSideBar ? "block" : "none",
            zIndex: "2",
            top: "0",
            position: "absolute",
          }}
        >
          <Mobnav />
        </div>
        <div
          className={collapsedSideBar ? "pagemask" : ""}
          onMouseDown={() => setCollapsedSideBar(false)}
        >
          {" "}
        </div>
      </div>




      {
        modelopen &&

        <Modal show={modelopen} onHide={() => setmodelopen(false)} backdropClassName="customamp" aria-labelledby="contained-modalter" centered>
          {/* onHide={handleClose}  */}

          <Modal.Body>
            <div className="inputboxsection">
              <img src={imageSection} />
              <OtpInput
                value={otp}
                onChange={(e) => otpchange(e)}
                numInputs={4}
                renderSeparator={<span
                  style={{
                    opacity: 0
                  }}
                >---</span>}
                renderInput={(props) => <input {...props} />}
              />
              <label htmlFor=""
                onClick={() => setmodelopen(false)}
              >Close</label>
            </div>

          </Modal.Body>

        </Modal>
      }
    </>
  );
};

export default NewHomePage;
