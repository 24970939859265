import React from "react";
import "./Holiding.scss";
import Navbar from "../components/navbar/navbar";

import logo1 from "../Image/inner4.png";
import upper from "../Image/starrcheck.svg";
import small1 from "../Image/nsmall1.png";
import small2 from "../Image/nsmall1.png";
import { useParams, useHistory } from "react-router-dom";

export default function Holiding({setshow,  setselectedtab,
  setmodelopen}) {
  const history = useHistory();
  return (
    <>
      {/* <Navbar /> */}
      <div className="HoldingPage">
      <div className="topheadearhoilder">
              <h1>Our Brands</h1>


              <p
               onClick={()=>
                
                {
                  setselectedtab("hoilding");
                  setmodelopen(true)
                }}>Click Here To See The Valuation Of Our Portfolio In Live Time</p>
              {/* <div className="porprotfoilo"
             
                
              >See Our Portfolio</div> */}
              </div>

        <div className="titleMain"
        onClick={()=>setshow("default")}
        >
          <div className="inner">
            {/* <img src={logo1} /> */}
            <h2>Other</h2>
          </div>
          <img  className="rotatetranfrom" src={upper}  />
        </div>

        <div className="title">
          <div className="tileName">Company</div>
          <div className="tileName">Overview</div>
          <div className="tileName">Status</div>
          <div className="tileName">Position</div>
          <div className="tileName">Website</div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img
                src={logo1}
                style={{
                  height: "35px",
                }}
              />
              <h6>NvestCampus</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            Built India’s first pure blockchain dev centre in Bangalore. Now managing developers from across the country.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Majority Shareholder</h5>
          </div>
          <div className="contentItem">
            <label>Website</label>
          </div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
            <img
                src={logo1}
                style={{
                  height: "35px",
                }}
              />
              <h6>NvestLabs</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A blockchain and AI education company which guarantees placement to all students via NvestCampus.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Development</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Majority Shareholder</h5>
          </div>
          <div className="contentItem">
            <label>Website</label>
          </div>
        </div>

        
      </div>
    </>
  );
}
