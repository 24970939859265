// import "./Timeline.scss";
// import React, { useState, useContext } from "react";
// import { useHistory } from "react-router-dom";
// import toronto from "../../Static/images/toronto.svg";
// import birmingham from "../../Static/images/birmingham.svg";
// import seattle from "../../Static/images/seattle.svg";
// import world from "../../Static/images/world.svg";
// import gx1 from "../../Static/images/gx1.svg";
// import nvestcampus from "../../Static/images/nvestcampus.svg";
// import teamforce from "../../Static/images/teamforce.svg";
// import viral from "../../Static/images/viral.svg";
// import marketverse from "../../Static/images/marketverse.svg";
// import inrgroup from "../../Static/images/inrgroup.svg";
// import entertainment from "../../Static/images/entertainment.svg";
// import gx3 from "../../Static/images/gx3.svg";

// import { MainContext } from "../../context/newContext";
// import { useEffect } from "react";

// const VALUES = [
//   {
//     id: 0,
//     name: "NvestBank",
//     year: "Q4 - 2017 | Toronto",
//     background: toronto,
//     text: "How We Changed Banking Forever",
//     desc: [
//       "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
//       "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
//       "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
//     ],
//     link: "/about/story/nvestbank",
//   },
//   {
//     id: 1,
//     name: "Gravitichain",
//     year: "Q1 - 2018 | Birmingham",
//     background: birmingham,
//     text: "How We Changed Banking Forever",
//     desc: [
//       "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
//       "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
//       "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
//     ],
//     link: "/about/story/gravitichain",
//   },
//   {
//     id: 2,
//     name: "Nvest Global",
//     year: "Q2 - 2018 | Seattle",
//     background: seattle,
//     text: "How We Changed Banking Forever",
//     desc: [
//       "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
//       "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
//       "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
//     ],
//     link: "/about/story/nvestglobal",
//   },
//   {
//     id: 3,
//     name: "Global X Change",
//     year: "Q1 - 2019 | Toronto",
//     background: gx1,
//     text: "How We Changed Banking Forever",
//     desc: [
//       "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
//       "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
//       "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
//     ],
//     link: "/about/story/globalxchange",
//   },
//   {
//     id: 4,
//     name: "NvestCampus",
//     year: "Q2 - 2019 | Bangalore",
//     background: nvestcampus,
//     text: "How We Changed Banking Forever",
//     desc: [
//       "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
//       "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
//       "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
//     ],
//     link: "/about/story/nvestcampus",
//   },
//   {
//     id: 5,
//     name: "Teamforce",
//     year: "Q2 - 2020 | Global",
//     background: teamforce,
//     text: "How We Changed Banking Forever",
//     desc: [
//       "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
//       "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
//       "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
//     ],
//     link: "/about/story/teamforce",
//   },
//   {
//     id: 6,
//     name: "Viral Group",
//     year: "Q1 - 2021 | Global",
//     background: viral,
//     text: "How We Changed Banking Forever",
//     desc: [
//       "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
//       "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
//       "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
//     ],
//     link: "/about/story/viralgroup",
//   },
//   {
//     id: 7,
//     name: "MarketsVerse",
//     year: "Q1 - 2022 | Global",
//     background: marketverse,
//     text: "How We Changed Banking Forever",
//     desc: [
//       "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
//       "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
//       "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
//     ],
//     link: "/about/story/marketsverse",
//   },
//   {
//     id: 8,
//     name: "INR Group",
//     year: "Q1 - 2022 | Hyderabad",
//     background: inrgroup,
//     text: "How We Changed Banking Forever",
//     desc: [
//       "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
//       "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
//       "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
//     ],
//     link: "/about/story/inrgroup",
//   },
//   {
//     id: 9,
//     name: "Entertainment Group",
//     year: "Q1 - 2022 | Pune",
//     background: entertainment,
//     text: "How We Changed Banking Forever",
//     desc: [
//       "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
//       "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
//       "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
//     ],
//     link: "/about/story/entertainmentgroup",
//   },
//   {
//     id: 10,
//     name: "GX 3.0",
//     year: "Q4 - 2022 | London",
//     background: gx3,
//     text: "How We Changed Banking Forever",
//     desc: [
//       "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
//       "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
//       "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
//     ],
//     link: "/about/story/gx3.0",
//   },
// ];

// const TimeLine = () => {
//   let history = useHistory();
//   const { index, setIndex, showEach, setShowEach } = useContext(MainContext);
//   useEffect(() => {
//     if (window.location.pathname.toLowerCase() === "/about/story") {
//       setShowEach("");
//     }
//     let result = VALUES.filter((item, k) => {
//       return item.link.toLowerCase() === window.location.pathname.toLowerCase();
//     });
//     if (result.length > 0) {
//       setShowEach(result[0]);
//     }
//   }, [window.location.pathname]);
//   return (
//     <>
//       {showEach === "" ? (
//         <div className="timeline-full">
//           {VALUES.map((each, id) => {
//             return (
//               <div
//               className="innsecardtimeline"
//                 style={{ marginTop: "-7.5px" }}
//                 onClick={() => {
//                   setShowEach(each);
//                   history.push(each.link);
//                 }}
//               >
//                 <div
//                   className="timebox"
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     width: "100%",
//                   }}
//                 >
//                   {/* <div className={index === each.id ? "time-name mark" : "time-name" } style={{width:"fit-content"}}>{each.name}</div>
//                 <div className="time-year">{each.year}</div> */}
//                   <div
//                     style={{
//                       backgroundImage: `url(${each.background})`,
//                       paddingTop: "30px",
//                       textAlign: "center",
//                       display: "flex",
//                       flexDirection: "column",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       height: "calc(33.33vh - 25.333px)",
//                       backgroundSize: "cover",
//                       backgroundRepeat: "no-repeat",
//                       zIndex: "1",
//                       cursor: "pointer",
//                       width: "100%",
//                       transform: "scale(1.01)",
//                     }}
//                     onClick={() => setIndex(id)}
//                   >
//                     <div className="time-name" style={{ width: "fit-content" }}>
//                       {each.name}
//                     </div>
//                     <div className="time-year">{each.year}</div>
//                   </div>
//                   <div
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       zIndex: "2",
//                       marginTop: "-7.5px",
//                       width: "100%",
//                     }}
//                   >
//                     <div
//                       style={{
//                         width: "calc(50% - 7.5px)",
//                         height: "5px",
//                         background: "#FFFFFF",
//                       }}
//                     ></div>
//                     {/* </div> */}
//                     <div
//                       className={index === each.id ? "present" : ""}
//                       style={{
//                         height: "15px",
//                         width: "15px",
//                         background: "#FFFFFF",
//                         borderRadius: "50%",
//                         cursor: "pointer",
//                       }}
//                       onClick={() => setIndex(id)}
//                     ></div>
//                     <div
//                       style={{
//                         width: "calc(50% - 7.5px)",
//                         height: "5px",
//                         background: "#FFFFFF",
//                       }}
//                     ></div>
//                     {/* <div className="time-place">{each.place}</div> */}
//                   </div>
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       ) : (
//         <div className="timeline-each">
//           <div
//             className="timeline-each-left"
//             style={{
//               backgroundImage: `url(${showEach.background})`,
//               backgroundSize: "cover",
//               backgroundRepeat: "no-repeat",
//               backgroundPositionX: "center",
//               textAlign: "center",
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//             onClick={() => {
//               setShowEach("");
//               history.push("/about/story");
//             }}
//           >
//             <div className="time-name" style={{ width: "fit-content" }}>
//               {showEach.name}
//             </div>
//             <div className="time-year">{showEach.year}</div>
//           </div>
//           <div className="timeline-each-right">
//             <div className="each-right-name">{showEach.name}</div>
//             <div className="each-right-text">{showEach.text}</div>
//             <div className="each-right-desc">
//               {showEach.desc.map((ele, j) => {
//                 return (
//                   <div className="each-right-singledesc">
//                     {ele}
//                     <br />
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default TimeLine;


import React, { useState, useContext,useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Timeline.scss";
import secondtimeline from '../../Static/secondtimeline.svg'
import porpnscas from '../../Static/porpnscas.svg'
import stmnsg from '../../Static/stmnsg.svg'
import { MainContext } from "../../context/newContext";
import arrowm from '../../Static/arrowm.svg'
import toronto from "../../Static/images/toronto.svg";
import birmingham from "../../Static/images/birmingham.svg";
import seattle from "../../Static/images/seattle.svg";
import world from "../../Static/images/world.svg";
import gx1 from "../../Static/images/gx1.svg";
import nvestcampus from "../../Static/images/nvestcampus.svg";
import teamforce from "../../Static/images/teamforce.svg";
import viral from "../../Static/images/viral.svg";
import marketverse from "../../Static/images/marketverse.svg";
import inrgroup from "../../Static/images/inrgroup.svg";
import entertainment from "../../Static/images/entertainment.svg";
import gx3 from "../../Static/images/gx3.svg";

const VALUES = [
  {
    id: 0,
    name: "NvestBank",
    year: "Q4 - 2017 | Toronto",
    background: toronto,
    text: "How We Changed Banking Forever",
    desc: [
      "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
      "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
      "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
    ],
    link: "/about/story/nvestbank",
  },
  {
    id: 1,
    name: "Gravitichain",
    year: "Q1 - 2018 | Birmingham",
    background: birmingham,
    text: "How We Changed Banking Forever",
    desc: [
      "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
      "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
      "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
    ],
    link: "/about/story/gravitichain",
  },
  {
    id: 2,
    name: "Nvest Global",
    year: "Q2 - 2018 | Seattle",
    background: seattle,
    text: "How We Changed Banking Forever",
    desc: [
      "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
      "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
      "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
    ],
    link: "/about/story/nvestglobal",
  },
  {
    id: 3,
    name: "Global X Change",
    year: "Q1 - 2019 | Toronto",
    background: gx1,
    text: "How We Changed Banking Forever",
    desc: [
      "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
      "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
      "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
    ],
    link: "/about/story/globalxchange",
  },
  {
    id: 4,
    name: "NvestCampus",
    year: "Q2 - 2019 | Bangalore",
    background: nvestcampus,
    text: "How We Changed Banking Forever",
    desc: [
      "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
      "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
      "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
    ],
    link: "/about/story/nvestcampus",
  },
  {
    id: 5,
    name: "Teamforce",
    year: "Q2 - 2020 | Global",
    background: teamforce,
    text: "How We Changed Banking Forever",
    desc: [
      "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
      "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
      "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
    ],
    link: "/about/story/teamforce",
  },
  {
    id: 6,
    name: "Viral Group",
    year: "Q1 - 2021 | Global",
    background: viral,
    text: "How We Changed Banking Forever",
    desc: [
      "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
      "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
      "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
    ],
    link: "/about/story/viralgroup",
  },
  {
    id: 7,
    name: "MarketsVerse",
    year: "Q1 - 2022 | Global",
    background: marketverse,
    text: "How We Changed Banking Forever",
    desc: [
      "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
      "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
      "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
    ],
    link: "/about/story/marketsverse",
  },
  {
    id: 8,
    name: "INR Group",
    year: "Q1 - 2022 | Hyderabad",
    background: inrgroup,
    text: "How We Changed Banking Forever",
    desc: [
      "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
      "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
      "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
    ],
    link: "/about/story/inrgroup",
  },
  {
    id: 9,
    name: "Entertainment Group",
    year: "Q1 - 2022 | Pune",
    background: entertainment,
    text: "How We Changed Banking Forever",
    desc: [
      "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
      "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
      "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
    ],
    link: "/about/story/entertainmentgroup",
  },
  {
    id: 10,
    name: "GX 3.0",
    year: "Q4 - 2022 | London",
    background: gx3,
    text: "How We Changed Banking Forever",
    desc: [
      "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives.",
      "His exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.",
      "In 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
    ],
    link: "/about/story/gx3.0",
  },
];
export default function Timeline() {
  const { index, setIndex, showEach, setShowEach } = useContext(MainContext);
  let history = useHistory();
const [detail,setdetail]=useState('')
    useEffect(() => {
    if (window.location.pathname.toLowerCase() === "/about/story") {
      setShowEach("");
    }
    let result = VALUES.filter((item, k) => {
      return item.link.toLowerCase() === window.location.pathname.toLowerCase();
    });
    if (result.length > 0) {
      setShowEach(result[0]);
    }
  }, [window.location.pathname]);
  return (
    <>
    {showEach === "" ? (

<>
<div className='TimelinePage'>
<div className='topSectioninnertime bcktint'>
<div className='cardinside'>
  <h1>GZI</h1>
  <div className='insidetect'>
    <div className='line'></div>
    <div className='text'
 
    >2016</div>
    <div className='line'></div>
  </div>
</div>
<div className='cardinside'
  onClick={() => {
                      setShowEach(VALUES[0]);
                      setdetail("NvestBank")
                      // history.push("/about/story/nvestbank")
                    }}
>
  <h1>NvestBank</h1>
  <div className='insidetect'>
    <div className='line'></div>
    <div className='text'
       style={{
        background:  detail=="NvestBank" &&  "linear-gradient(305deg, #5E3199  0%, #B580F2 100%)",
        color: detail=="NvestBank" && "white"
      }}
    >2017</div>
    <div className='line'
  
    ></div>
  </div>
</div>
<div className='cardinside'
 onClick={() => {
  setShowEach(VALUES[4]);
  setdetail("NvestCampus")
  // history.push("/about/story/nvestbank")
}}
>
  <h1>NvestCampus</h1>
  <div className='insidetect'>
    <div className='line'
      style={{
        background: "linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%)"
      }}
    ></div>
    <div className='text'
       style={{
        background:  detail=="NvestCampus" &&  "linear-gradient(305deg, #5E3199  0%, #B580F2 100%)",
        color: detail=="NvestCampus" && "white"
      }}
    >2018</div>
    <div className='line'
    style={{
      background:"#B580F2"
    }}
    ></div>
  </div>
</div>
<div className='cardinside'
 onClick={() => {
  setShowEach(VALUES[3]);
  setdetail("Global X Change")
  // history.push("/about/story/nvestbank")
}}
>
  <h1>Global X Change</h1>
  <div className='insidetect'>
    <div className='line'
       style={{
        background:"#B580F2"
      }}
    ></div>
    <div className='text'
        style={{
          background:  detail=="Global X Change" &&  "linear-gradient(305deg, #5E3199  0%, #B580F2 100%)",
          color: detail=="Global X Change" && "white"
        }}
    >2019</div>
    <div className='line'
       style={{
        background:"#B580F2"
      }}
    ></div>
  </div>
</div>

 </div>

 <div className='topSectioninnertime bcktint'
 style={{
  background:`url(${secondtimeline})`
 }}
 >
<div className='cardinside'>
  <h1 className='mar'>Starfish 1</h1>

</div>
<div className='cardinside'>
  <h1 className='mar'>Starfish 2</h1>

</div>
<div className='cardinside'>
  <h1 className='mar'>Starfish 3</h1>
 
</div>
<div className='cardinside'>
  <h1 className='mar'>Starfish 4</h1>
 
</div>

 </div>

 <div className='showinside'>
<div className='innershowtime'>
<div className='topSectioninnerprops bcktint'
style={{
  background:`url(${stmnsg})`
}}
>
<div className='cardinsideinside'>
  <h1>Viralverse</h1>
  <div className='insidetect'>
    <div className='line'></div>
    <div className='text'>2020</div>
    <div className='line'></div>
  </div>
</div>
<div className='cardinsideinside'>
  <h1
   onClick={() => {
    setShowEach(VALUES[7]);
    setdetail("Marketsverse")
    // history.push("/about/story/nvestbank")
  }}
  >Marketsverse</h1>
  <div className='insidetect'>
    <div className='line'
    
    ></div>
    <div className='text'
       style={{
        background:  detail=="Marketsverse" &&  "linear-gradient(305deg, #5E3199  0%, #B580F2 100%)",
        color: detail=="Marketsverse" && "white"
      }}
    >2021</div>
    <div className='line'
     style={{
      background: "linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%)"
    }}
      ></div>
  </div>
</div>
<div className='cardinsideinside'
  onClick={() => {
    setShowEach(VALUES[8]);
    setdetail("INR Group")
    // history.push("/about/story/nvestbank")
  }}
>
  <h1>INR Group</h1>
  <div className='insidetect'>
    <div className='line'
      style={{
        background:"#B580F2"
      }}></div>
    <div className='text'
      style={{
        background:  detail=="INR Group" &&  "linear-gradient(305deg, #5E3199  0%, #B580F2 100%)",
        color: detail=="INR Group" && "white"
      }}
    >2022</div>
    <div className='line'
      style={{
        background:"#B580F2"
      }}
    ></div>
  </div>
</div>

 </div>



<div className='topSectioninnerprops bcktint'
 style={{
  background:`url(${porpnscas})`
 }}
 >
<div className='cardinsideinside'>
  <h1 className='mar'>Starfish 5</h1>

</div>
<div className='cardinsideinside'>
  <h1 className='mar'>Starfish 6</h1>

</div>
<div className='cardinsideinside'>
  <h1 className='mar'>Starfish 7</h1>
 
</div>


 </div>
</div>
<div className='inndetextchnage'>
<img src={arrowm}/>
The Merger
</div>
 </div>

    </div>

</>


    ):(
    <div className="timeline-each">
           <div
            className="timeline-each-left"
            style={{
              backgroundImage: `url(${showEach.background})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "center",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              setShowEach("");
              history.push("/about/story");
            }}
          >
            <div className="time-name" style={{ width: "fit-content" }}>
              {showEach.name}
            </div>
            <div className="time-year">{showEach.year}</div>
          </div>
          <div className="timeline-each-right">
            <div className="each-right-name">{showEach.name}</div>
            <div className="each-right-text">{showEach.text}</div>
            <div className="each-right-desc">
              {showEach?.desc?.map((ele, j) => {
                return (
                  <div className="each-right-singledesc">
                    {ele}
                    <br />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
    )
            }
       
    </>
  
   )
 }

