import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../../context/newContext";
import './navbar.scss';
import { useHistory } from "react-router-dom";

const sideBar = [
  {
    title: "About",
    subMenu: ["Story", "Founder", "Footprint", "Roadmap"],
  },
  {
    title: "Subsidiaries",
    subMenu: ["GX", "Viral", "INR", "Employment", "Experiences", "CryptoStartups", "Spending", "Businesses"],
  },
];

const Mobnav = () => {
  let history = useHistory();
    const {
        nav,
        setnav,
        sideNav,
        setSideNav,
        index,
        setIndex,
        collapsedSideBar,
        setCollapsedSideBar,
        mobileScreensNav,
        setMobileScreensNav,
        setSubsidaryMenu,
      } = useContext(MainContext);
    
      const handleNav = (item, itemName) => {
        if (item === "home") {
    
          var element = document.getElementById("home");

          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          setCollapsedSideBar(false)

        } else {
          setMobileScreensNav(itemName);
          // if(itemName === "Subsidiaries"){
            setSideNav("")
          // }
          
          setCollapsedSideBar(false)
          history.push(`/${itemName.toLowerCase()}`)
        }

        if(itemName === "Development"){
          window.open("https://block.software/", "_blank");
          window.location.reload();
        }
      };

      const handleSidebar = (each, value, item) => {
        // if(title === "About" && value === ""){
        //   setMobileScreensNav(title);
        //   // setSideNav(0);
        // }else{
        //   setMobileScreensNav(title);
        //   if(value !== 7){
        //     setSideNav(value);
        //     setSubsidaryMenu(0);
        //   }
        //   setCollapsedSideBar(false)
        // }
        if(value !== ""){
          setCollapsedSideBar(false)
          history.push(`/${each.title.toLowerCase()}/${item.toLowerCase()}`)
        }else{
          setCollapsedSideBar(false)
          history.push(`/${each.title.toLowerCase()}`)
        }
        

      }


  return (
    <div className={collapsedSideBar ? "full-mob-nav" :"close-mob-nav"}>
         <div className="mob-nav-menu-box">
          <div
            className="nav-each-menu"
            style={{ fontWeight: mobileScreensNav === "About" ? "700" : "", borderBottom: mobileScreensNav === "About" ? "1px solid #100F0D " : "" }}
            onClick={() => handleNav("menu", "About")}
          >
            About
          </div>
          <div
            className="nav-each-menu"
            style={{ fontWeight: mobileScreensNav === "Subsidiaries" ? "700" : "", borderBottom: mobileScreensNav === "Subsidiaries" ?"1px solid #100F0D " : "" }}
            onClick={() => handleNav("menu", "Subsidiaries")}
          >
            Subsidiaries
          </div>
          <div
            className="nav-each-menu"
            style={{ fontWeight: mobileScreensNav === "Development" ? "700" : "", borderBottom: mobileScreensNav === "Development" ? "1px solid #100F0D " : "" }}
            onClick={() => handleNav("menu", "Development")}
          >
            Development
          </div>
          {/* <div className="nav-button">Raise Capital</div> */}
        </div>
        <div className="side-all-menu" style={{display: mobileScreensNav === "Development" ? "none" : ""}}>
          {sideBar.filter((element) => {
            return(
              element.title === mobileScreensNav
            )
          }).map((each, j) => {
            return(
              <div key={j}>
                {each.subMenu.map((item, j) => {
                  return(
                <div className={
                            each.title === mobileScreensNav && sideNav === j
                              ? "mob-menu-selected"
                              : "mob-each-side-menu"
                          } onClick={() => j !== 7 ? handleSidebar(each, j, item) : ""}>{item}</div>
                  )})}
              </div>
            )
          })}
        </div>
          
    </div>
  )
}

export default Mobnav