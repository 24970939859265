import React from "react";
import styles from "./cancellation.module.scss";
import Navbar from "../../components/navbar/navbar";

const Cancellation = () => {
  return (
    <>
      <Navbar />
      <div className={styles.container}>
        <h1>
          <strong>Cancellation & Refund Policy</strong>
        </h1>
        <p>
          At Starfish Accelerator Partners Private Limited ("Starfish
          Accelerator"), we strive to provide exceptional services to our
          clients. We understand that circumstances may arise where
          cancellations and refunds are necessary. This Cancellation & Refund
          Policy outlines the terms and conditions governing cancellations and
          refunds of our services.
        </p>

        <h2>
          <strong>1. Cancellation Policy</strong>
        </h2>
        <ol>
          <li>
            To request a cancellation of your engagement with Starfish
            Accelerator, please notify us in writing via email or through our
            official communication channels.
          </li>
          <li>
            Your cancellation request should include your name, contact
            information, and relevant details regarding the service for which
            you seek cancellation.
          </li>
          <li>
            The cancellation will be effective from the date we receive your
            written notification.
          </li>
          <li>
            Please note that specific cancellation terms and conditions may
            apply, as outlined in your service agreement or contract.
          </li>
        </ol>

        <h2>
          <strong>2. Refund Policy</strong>
        </h2>
        <ol>
          <li>
            Refund eligibility and amount are subject to the terms and
            conditions specified in your service agreement or contract with
            Starfish Accelerator.
          </li>
          <li>
            If you cancel your engagement with Starfish Accelerator before the
            commencement of services, you may be eligible for a refund of the
            fees paid, subject to any applicable deductions.
          </li>
          <li>
            Refund amounts, if applicable, will be determined based on factors
            such as the stage and progress of the services provided, as well as
            any applicable terms specified in your service agreement or
            contract.
          </li>
          <li>
            Refunds, if applicable, will be processed within a reasonable
            timeframe and using the original payment method used for the
            transaction, unless otherwise agreed upon.
          </li>
        </ol>

        <h2>
          <strong>3. Non-Refundable Charges</strong>
        </h2>
        <ol>
          <li>
            Certain charges may be non-refundable and will be clearly specified
            in your service agreement or contract. Examples of non-refundable
            charges may include administrative fees, registration fees, or
            third-party expenses incurred on your behalf.
          </li>
          <li>
            Any fees or expenses incurred by Starfish Accelerator prior to the
            cancellation request may not be refundable.
          </li>
        </ol>

        <h2>
          <strong>4. Dispute Resolution</strong>
        </h2>
        <ol>
          <li>
            In the event of any dispute regarding cancellations or refunds, both
            parties agree to engage in good faith discussions to resolve the
            matter amicably.
          </li>
          <li>
            If the dispute remains unresolved, it may be escalated to the
            appropriate legal channels as specified in your service agreement or
            contract.
          </li>
        </ol>

        <h2>
          <strong>5. Changes to the Policy</strong>
        </h2>
        <ol>
          <li>
            Starfish Accelerator reserves the right to modify or update this
            Cancellation & Refund Policy at any time. Any changes will be
            effective immediately upon posting the revised policy on our website
            or notifying you directly.
          </li>
          <li>
            It is your responsibility to review the policy periodically to stay
            informed about any updates.
          </li>
        </ol>

        <p>
          Please note that this Cancellation & Refund Policy should be read in
          conjunction with the specific terms and conditions outlined in your
          service agreement or contract. If you have any questions or require
          further clarification, please contact us through our official
          communication channels.
        </p>

        <p>Thank you for your understanding and cooperation.</p>

        <p>Starfish Accelerator Partners Private Limited</p>
      </div>
    </>
  );
};

export default Cancellation;
