import React from "react";
import "./holdings.scss";
import ProfitIcon from "../../Static/profitico.svg";
import { PieChart } from 'react-minimal-pie-chart';
import TaxIcon from "../../Static/taxicon.svg";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend
} from "recharts";




function Holdings() {
    const data = [
        {
            name: "12:00AM",
            Holdings: 33000,
            amt: 2400
        },
        {
            name: "1:00AM",
            Holdings: 30000,
            amt: 2210
        },
        {
            name: "2:00AM",
            Holdings: 45000,
            amt: 2290
        },
        {
            name: "3:00AM",
            Holdings: 37890,
            amt: 2000
        },
        {
            name: "4:00AM",
            Holdings: 24680,
            amt: 2181
        },
        {
            name: "5:00AM",
            Holdings: 23890,
            amt: 2500
        },
        {
            name: "12:00AM",
            Holdings: 33000,
            amt: 2400
        },
        {
            name: "1:00AM",
            Holdings: 30000,
            amt: 2210
        },
        {
            name: "2:00AM",
            Holdings: 45000,
            amt: 2290
        },
        {
            name: "3:00AM",
            Holdings: 37890,
            amt: 2000
        },
        {
            name: "4:00AM",
            Holdings: 24680,
            amt: 2181
        },
        {
            name: "5:00AM",
            Holdings: 23890,
            amt: 2500
        },
        {
            name: "12:00AM",
            Holdings: 33000,
            amt: 2400
        },
        {
            name: "1:00AM",
            Holdings: 30000,
            amt: 2210
        },
        {
            name: "2:00AM",
            Holdings: 45000,
            amt: 2290
        },
        {
            name: "3:00AM",
            Holdings: 37890,
            amt: 2000
        },
        {
            name: "4:00AM",
            Holdings: 24680,
            amt: 2181
        },
        {
            name: "5:00AM",
            Holdings: 23890,
            amt: 2500
        },
    ];
    return (
        <>
            <div className="topdata">
                <div className="main-hold">
                    <div className="holdingshead">
                        <p className="title-hold">Total Holdings</p>
                        <p className="num-hold">₹23,135.12</p>
                        <div className="datachange">
                            <img src={ProfitIcon} alt="green-arrow" className="green-arrow" />
                            <p className="num-chng">4.59%</p>
                            <p className="chng-val">(+₹212.46)</p>
                        </div>
                    </div>
                    <div className="vl-hold"></div>
                </div>
                <div className="right-hold">
                    <div className="pie-width">
                        <PieChart radius={50}
                            data={[
                                { title: 'taxchain', value: 50, color: '#5E3199 ' },
                                { title: 'indianotc', value: 15, color: '#B580F2' },
                                { title: 'sharetoken', value: 25, color: '#5E3199 57' }
                            ]}

                            lineWidth={60}

                        />
                    </div>
                    <div className="group-cards">
                    <div className="share-card">
                        <div className="tax-text">
                            <img src={TaxIcon} alt="tax-icon" className="tax-icon" />
                            <p className="textcard">TaxChains</p>
                        </div>
                        <div className="share-num">
                            <p className="share-val">₹1,245.45</p>
                            <p className="share-chng">(24.5%)</p>
                        </div>
                    </div>
                    <div className="share-card">
                        <div className="tax-text">
                            <img src={TaxIcon} alt="tax-icon" className="tax-icon" />
                            <p className="textcard">TaxChains</p>
                        </div>
                        <div className="share-num">
                            <p className="share-val">₹1,245.45</p>
                            <p className="share-chng">(24.5%)</p>
                        </div>
                    </div>
                    <div className="share-card">
                        <div className="tax-text">
                            <img src={TaxIcon} alt="tax-icon" className="tax-icon" />
                            <p className="textcard">TaxChains</p>
                        </div>
                        <div className="share-num">
                            <p className="share-val">₹1,245.45</p>
                            <p className="share-chng">(24.5%)</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <hr className="hr-port-sty" />
            <div className="time-frame">
                <p className="num-frame num-active">24H</p>
                <p className="num-frame">7D</p>
                <p className="num-frame">1M</p>
                <p className="num-frame">YTD</p>
                <p className="num-frame num-right">1Y</p>
            </div>
            <hr className="hr-btm-sty" />
            <div className="chart-disp" style={{ height: (window.innerHeight - 50) }}>
                <ResponsiveContainer>
                    <LineChart
                        // width={1000}
                        // height={600}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                    >
                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                        {/* <XAxis dataKey="name"
                            type="number"
                            ticks={[1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000]}
                            domain={[1000, 9000]} /> */}
                        <XAxis dataKey="name" />
                        <YAxis type="number"
                            ticks={[22000, 25000, 28000, 31000, 34000, 37000, 40000, 43000, 46000, 49000]}
                            domain={[25000, 50000]} />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="Holdings"
                            stroke="#4CAF50"
                            strokeWidth={2}

                        // activeDot={{ r: 8 }}
                        />

                        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </>
    )
}

export default Holdings