import React, { memo, useContext, useEffect, useState, useMemo } from "react";
import { Agency } from '../../context/Context';
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Marker,
  Geography
} from "react-simple-maps";
import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale"
import sortBy from "lodash/sortBy";

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

const rounded = num => {
  if (num > 1000000000) {
    return Math.round(num / 100000000) / 10 + "Bn";
  } else if (num > 1000000) {
    return Math.round(num / 100000) / 10 + "M";
  } else {
    return Math.round(num / 100) / 10 + "K";
  }
};



const MapChart = ({ setTooltipContent }) => {
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [clickedCity, setClickedCity] = useState("");
  const agency = useContext(Agency)
  const { compainesvalue, flitermapfunction } = agency;
  const handleClick = (geo) => {
    setClickedCity(geo.properties.name);


  };

  console.log("123123132", maxValue)
  const markers = [
    {
      markerOffset: 25,
      name: "",
      coordinates: [77.069710, 28.679079]
    },
  {
        markerOffset: 25,
        name: "",
        coordinates: [-95.695000, 55.424721]
      },
      {
        markerOffset: 25,
        name: "",
        coordinates: [129.128998, -25.282001]
      },
      {
        markerOffset: 25,
        name: "",
        coordinates: [-0.118092, 51.509865]
      },
      {
        markerOffset: 25,
        name: "",
        coordinates: [-3.703790, 40.416775]
      },
    
  ];
 
  const colorScale = scaleLinear()
    .domain([0, 100000000, 1338612970]) // Max is based on China
    .range(["#5E3199 ", "#5E3199 ", "#5E3199 "])

  return (
    <>

      <ComposableMap data-tip="" >
        {/* <ZoomableGroup> */}
          <Geographies geography={geoUrl}>
            {({ geographies, projection }) =>
              geographies.map(geo => {
              
                console.log("zcxczxcbfdf", geo.properties)
                return (

                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    //  fill={"India" ? "url('#lines')" : "#B8B9BA"}
             
                    projection={projection}
                    onMouseEnter={   () => {
                      const { name, POP_EST } = geo.properties;  
                      
                           setTooltipContent(name=="Canada"||name=="India" || name=="Australia" || name=="Spain"||name=="United Kingdom"?`${name}`:"")

                    }}

                    onClick={() => {
                      const { name, POP_EST } = geo.properties;
                      flitermapfunction(`${name}`);
                    }}



                    onMouseLeave={() => {
                     setTooltipContent("");

                    }}
                    width={800}
                    height={400}
                    style={{

                      default: {

                        fill: "#e3e3e3",
                        outline: "none"
                      },
                      hover: {
                        fill: "#5E3199 ",
                        outline: "none"
                      },
                      pressed: {
                        fill: "#5E3199 ",
                        stroke: "#5E3199 ",
                        strokeWidth: 1,
                        outline: "none",
                      }
                    }}
                  />
                )
              }
              )
            }
          </Geographies>
        {/* </ZoomableGroup> */}
        {markers.map(({ name, coordinates, markerOffset }) => (
          <Marker key={name} coordinates={coordinates}>
            {/* <circle r={5} fill="#5E3199 " stroke="#fff" strokeWidth={3} /> */}
            <text
              textAnchor="middle"
              y={markerOffset}
              style={{ fontFamily: "system-ui", fill: "#5E3199 " }}
            >
              {name}
            </text>
          </Marker>
        ))}
      </ComposableMap>
    </>
  );
};

export default memo(MapChart);
