import React from 'react'
import so1 from '../../Static/so1.svg'
import so2 from '../../Static/so2.svg'
import so3 from '../../Static/so3.svg'
import so4 from '../../Static/so4.svg'
import so5 from '../../Static/so5.svg'
import so6 from '../../Static/so6.svg'
import so7 from '../../Static/so7.svg'
import so8 from '../../Static/so8.svg'
import so9 from '../../Static/so9.svg'
import shoo from '../../Static/sshh.png'
import './founders.scss'
export default function index({ setfoundName }) {
  return (
    <div className='founderpage'>
      <h5>Our Founders</h5>
      {/* <div className='innerfounder'>
    <div>
<h1>Philip T</h1>
<p>Chief Executive Officer</p>
</div>
<div className='imgSectionimg'>
<img className='img'  src={so1}/>
<img className='img' src={so2}/>
<img className='img' src={so3}/>
<img className='img' src={so4}/>
<img className='img' src={so5}/>
<img className='img' src={so6}/>
<img className='img' src={so7}/>
<img className='img' src={so8}/>
<img className='img' src={so9}/>
</div>
<label>Learn About Philip</label>
<img className='shoo1' src=""/>
    </div> */}
      <div className='innerfounder'
        style={{
          borderTop: "0.5px solid #EDEDED",
          borderBottom: "0.5px solid #EDEDED"
        }}
      >
        <div>
          <h1>Shorupan P</h1>
          <p>Chief Technology Officer</p>
        </div>
        <div className='imgSectionimg'>
          <img className='img' src={so1} />
          <img className='img' src={so2} />
          <img className='img' src={so3} />
          <img className='img' src={so4} />
          <img className='img' src={so5} />
          <img className='img' src={so6} />
          <img className='img' src={so7} />
          <img className='img' src={so8} />
          <img className='img' src={so9} />
        </div>
        <label
          onClick={() => {
            setfoundName("Shorupan");
            // history.push("/about/team/Shorupan");
          }}
        >Learn About Shorupan</label>
        <img className='shoo' src={shoo} />
      </div>

    </div>
  )
}
