import React, { useEffect, useState } from "react";
import Investor from "./Investor";
import { useParams, useHistory } from "react-router-dom";

import Exprience from "./ExpirenceGroup";
import Terminal from "./Terminal";
import Nvest from "./Nvest";
import logo4 from "../Image/inner4.png";
import Navbar from "../components/navbar/navbar";
import upper from "../Image/starrcheck.svg";
import logo2 from "../Image/inner2.png";
import logo1 from "../Image/logo1.png";
import logo3 from "../Image/inner8.png";
export default function Holiding({
  setmodelopen, setselectedtab
}) {
  const [show, setshow] = useState("default");
  const history = useHistory();
  let { name } = useParams();
  useEffect(() => {
    if (name == "") {
      setshow("default");
    } else if (name == "enterprise") {
      setshow("invest");
    } else if (name == "consumer") {
      setshow("expirence");
    } else if (name == "offline") {
      setshow("termial");
    } else if (name == "other") {
      setshow("nvest");
    }
  }, [name]);
  const handle = () => {
    switch (show) {
      case "invest":
        return <Investor setshow={setshow}

          setselectedtab={setselectedtab}
          setmodelopen={setmodelopen}
        />;
      case "expirence":
        return <Exprience setshow={setshow} setselectedtab={setselectedtab}
          setmodelopen={setmodelopen} />;
      case "termial":
        return <Terminal setshow={setshow} setselectedtab={setselectedtab}
          setmodelopen={setmodelopen} />;
      case "nvest":
        return <Nvest setshow={setshow} setselectedtab={setselectedtab}
          setmodelopen={setmodelopen} />;
      case "default":
        return (
          <>
            <div className="HoldingPage" id="menuHolding">
              <div className="topheadearhoilder">
                <h1>Our Clients</h1>


                <p
                  onClick={() => {
                    setselectedtab("hoilding");
                    setmodelopen(true)
                  }}>Click Here To See The Valuation Of Our Portfolio In Live Time</p>
                {/* <div className="porprotfoilo"
             
                
              >See Our Portfolio</div> */}
              </div>
              <div
                className="titleMainCustom titleMain"
                onClick={() => {
                  history.push("/holidings/enterprise");
                  setshow("invest");
                }}
              >
                <div className="inner">
                  {/* <img src={logo1} /> */}
                  <h2>Insurance</h2>


                </div>
                <img src={upper} />
              </div>
              <div
                className="titleMainCustom titleMain"

                onClick={() => {
                  history.push("/holidings/consumer");
                  setshow("expirence");
                }}
              >
                <div className="inner">
                  <h2>Care Providers</h2>
                </div>
                <img src={upper} />
              </div>
              <div
                className="titleMainCustom titleMain"
                // onClick={() => setshow("termial")}
                onClick={() => {
                  history.push("/holidings/offline");
                  setshow("termial");
                }}
              >
                <div className="inner">
                  <h2>Wellness Brands</h2>
                </div>
                <img src={upper} />
              </div>
              {/* <div
                className="titleMainCustom titleMain"
                onClick={() => {
                  history.push("/holidings/other");
                  setshow("nvest");
                }}
           
              >
                <div className="inner">
                  <h2>Other</h2>
                </div>
                <img src={upper} />
              </div> */}
            </div>
          </>
        );
    }
  };
  return (
    <>
      {/* <Navbar /> */}
      {handle()}
    </>
  );
}
