import React, { useEffect, useRef, useState, useContext } from "react";

import starfish from "../Static/supernova.svg";
import Nav from '../components/navbar/navbar'
import "./super.scss";
const Index = () => {
  return (
    <div>
      <div> </div>
      {/* <SplashHeader /> */}
      <>
        <div
        // style={{
        //   display: showallnav ? "" : mobdescribe === false ? "" : "none",
        // }}

        ></div>
        <Nav/>
      </>
      <div className="landingPage">
        <div className="pageWrap">
          <>
            <div className="mainStarfish">
              <div className="starfish">
                <div data-aos="fade-right">
                  <h1>
                    Take your startup from an idea to market ready in 6 months.
                  </h1>
                  <label
                  onClick={()=>window.open("https://checkout.stripe.com/c/pay/cs_live_a1rHuhRjZydax5inLa1CMzkJS6QoSQPQIJKqJeJHGmmhDKVAqmkI1OA0xl#fidkdWxOYHwnPyd1blppbHNgWjA0S0piXW9WQHwzcX1IMV08SjFAU31rS1ZwZzdsVDx%2Fb18zanV9YDd8dTRjdkNgSW5BS3dOZ0ZWUGtndUpma3VyQ0B0VGJkM3ViaV1tPTxGV2lMMG1SdjRiNTVBSlZhc1xpTycpJ3VpbGtuQH11anZgYUxhJz8nPXJIZ05DYD19YXxRNk1CPEREJ3gl","blank")}
                  >Get Started Now</label>
                </div>
                <div className="blackimg" data-aos="fade-left">
                  <img src={starfish} />
                </div>
              </div>
              <div className="section2stair">
                <h1>The STA Onboarding Process.</h1>
                <div className="cards">
                  <div className="items">
                    <h4>1</h4>
                    <h2>Submit Your Idea</h2>
                    <h5>
                      Fill out our simple form <span>Here</span>. Tell us what
                      your startup intends to build and the size of your current
                      operation
                    </h5>
                  </div>

                  <div className="items">
                    <h4>2</h4>
                    <h2>Virtual Pitch (Business)</h2>
                    <h5>
                      If we like your vision, you will be requested to perform a
                      15 minute pitch via Zoom in front of one of our analysts.
                    </h5>
                  </div>

                  <div className="items">
                    <h4>3</h4>
                    <h2>Tech Deep Dive</h2>
                    <h5>
                      If the pitch goes well, then you will conduct a call with
                      one of our senior technology executives to unpack the
                      software you envision to build.
                    </h5>
                  </div>

                  <div className="items">
                    <h4>4</h4>
                    <h2>Starfish Term Sheet</h2>
                    <h5>
                      After internal deliberation, Starfish may issue you the
                      standard <span>STA Term Sheet</span>. After which you will
                      have 48 hrs to accept or reject the offer.
                    </h5>
                  </div>

                  <div className="items">
                    <h4>5</h4>
                    <h2>We Build Your Product</h2>
                    <h5>
                      Over the next 6 months, Marketsverse will build the first
                      version of your product while Starfish gets you ready for
                      your capital raise/product launch.
                    </h5>
                  </div>

                  <div className="items">
                    <h4>6</h4>
                    <h2>Share Buy Back Upon Launch</h2>
                    <h5>
                      Once your product is ready to launch you can choose to buy
                      back the shares you gave to Starfish or keep us onboard
                      into your launch.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default Index;
