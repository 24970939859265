import React, { useEffect, useState } from "react";
import Investor from "./Investor";
import { useParams, useHistory } from "react-router-dom";

import Exprience from "./ExpirenceGroup";
import Terminal from "./Terminal";
import Nvest from "./Nvest";
import logo4 from "../Image/inner4.png";
import Navbar from "../components/navbar/navbar";
import upper from "../Image/starrcheck.svg";
import logo2 from "../Image/inner2.png";
import logo1 from "../Image/logo1.png";
import logo3 from "../Image/inner8.png";
export default function Holiding({
  setmodelopen,setselectedtab
}) {
  const [show, setshow] = useState("default");
  const history = useHistory();
  let { name } = useParams();
  useEffect(() => {
    if (name == "") {
      setshow("default");
    } else if (name == "enterprise") {
      setshow("invest");
    } else if (name == "consumer") {
      setshow("expirence");
    } else if (name == "offline") {
      setshow("termial");
    } else if (name == "other") {
      setshow("nvest");
    }
  }, [name]);
  const handle = () => {
    switch (show) {
      case "invest":
        return <Investor setshow={setshow} />;
      case "expirence":
        return <Exprience setshow={setshow} />;
      case "termial":
        return <Terminal setshow={setshow} />;
      case "nvest":
        return <Nvest setshow={setshow} />;
      case "default":
        return (
          <>
            <div className="HoldingPage" id="menuHolding">
              <div className="topheadearhoilder">
              <h1>Our Brands</h1>
              <div className="porprotfoilo"
              onClick={()=>
                
                {
                  setselectedtab("hoilding");
                  setmodelopen(true)
                }}
                
              >See Our Portfolio</div>
              </div>
              <div
                className="titleMainCustom titleMain"
                onClick={() => {
                  
                  setshow("invest");
                }}
              >
                <div className="inner">
                  {/* <img src={logo1} /> */}
                  <h2>Enterprise Tech</h2>

             
                </div>
                <img src={upper} />
              </div>
              <div
                className="titleMainCustom titleMain"
              
                onClick={() => {
                
                  setshow("expirence");
                }}
              >
                <div className="inner">
                  <h2>Consumer Tech</h2>
                </div>
                <img src={upper} />
              </div>
              <div
                className="titleMainCustom titleMain"
                // onClick={() => setshow("termial")}
                onClick={() => {
                
                  setshow("termial");
                }}
              >
                <div className="inner">
                  <h2>Offline</h2>
                </div>
                <img src={upper} />
              </div>
              {/* <div
                className="titleMainCustom titleMain"
                onClick={() => {
                  history.push("/holidings/other");
                  setshow("nvest");
                }}
           
              >
                <div className="inner">
                  <h2>Other</h2>
                </div>
                <img src={upper} />
              </div> */}
            </div>
          </>
        );
    }
  };
  return (
    <>
      {/* <Navbar /> */}
      {handle()}
    </>
  );
}
