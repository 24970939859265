import React from "react";
import "./Holiding.scss";
import { useParams, useHistory } from "react-router-dom";

import Navbar from "../components/navbar/navbar";
import logo1 from "../Image/inner2.png";
import upper from "../Image/starrcheck.svg";
import small1 from "../Image/ee1.png";
import small2 from "../Image/ee2.png";
import small3 from "../Image/ee3.png";
import small4 from "../Image/ee4.png";
import small5 from "../Image/ee5.png";
import small6 from "../Image/ee6.png";
import small8 from "../Image/ee7.png";
import small9 from "../Image/ee8.png";
import small10 from "../Image/e11.png";
import small11 from "../Image/e12.png";
import small12 from "../Image/e13.png";
import small13 from "../Image/14.png";
import asd from '../Image/e10.png'
export default function Holiding({setshow,

  setselectedtab,
  setmodelopen
}) {
  const history = useHistory();
  return (
    <>
   {/* <Navbar/> */}
      <div className="HoldingPage">
      <div className="topheadearhoilder">
              <h1>Our Clients</h1>


              <p
               onClick={()=>
                
                {
                  setselectedtab("hoilding");
                  setmodelopen(true)
                }}>Click Here To See The Valuation Of Our Portfolio In Live Time</p>
              {/* <div className="porprotfoilo"
             
                
              >See Our Portfolio</div> */}
              </div>

        <div className="titleMain"
         onClick={()=>setshow("default")}
        >
          <div className="inner">
            {/* <img src={logo1} /> */}
            <h2>Consumer Tech</h2>
          </div>
          <img className="rotatetranfrom" src={upper} />
        </div>

        <div className="title">
          <div className="tileName">Company</div>
          <div className="tileName">Overview</div>
          <div className="tileName">Status</div>
          <div className="tileName">Position</div>
          <div className="tileName">Website</div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small1} />
              <h6>Accountants.io</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            They enable accountants to digitalize their practice by reinventing how their clients discover and engage with them.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Pre-Launch</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
            onClick={()=>window.open("https://accountants.io/","_blank")}
            >Website</label>
          </div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small2} />
              <h6>Realtor</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            Sell your property on all listing platforms while overseeing what your real estate agent does throughout the transaction.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Development</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
                  onClick={()=>window.open("https://realtor.app/","_blank")}
            >Website</label>
          </div>
        </div>


        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small3} />
              <h6>IndianNights</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A revolution in relationship formation which leverages venue experiences to maximize serendipitous encounters.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
             <h5>Development</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
                    onClick={()=>window.open("https://indiannights.com/","_blank")}
            >Website</label>
          </div>
        </div>

        
        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small4} />
              <h6>InstaLegal</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            Enabling legal professionals to digitalize their practice by reinventing how their clients discover and engage with them.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
             <h5>Development</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
              onClick={()=>window.open("https://instalegal.com/","_blank")}
            >Website</label>
          </div>
        </div>

     
        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small5} />
              <h6>Assets.io</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            An alternative asset lending platform allows users to get instantly approved loans against their mutual funds.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Development</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
            
            onClick={()=>window.open("https://assets.io/","_blank")}
            >Website</label>
          </div>
        </div>



        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small6} />
              <h6>Web3Today</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A news platform which pay readers to learn about emergent technologies like Ai, Blockchain, and more.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
                onClick={()=>window.open("https://web3today.io/","_blank")}
            >Website</label>
          </div>
        </div>



        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small8} />
              <h6>AiProWallet</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            IndianInvestor.com is a capital markets platform which offer and end to end solution for Indian.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
                onClick={()=>window.open("https://aiprowallet.com/","_blank")}
            >Website</label>
          </div>
        </div>


        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small9} />
              <h6>Retired</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            An AI-Powered retirement planner which maps your financial goals over the best investments for your risk profile.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Pre-Launch</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
                      onClick={()=>window.open("https://retired.app/","_blank")}
            >Website</label>
          </div>
        </div>


        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={asd} />
              <h6>Raha</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            It helps companies build and manage employee wellness benefits ranging from insurance to mental health.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Development</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
              onClick={()=>window.open("https://rahainsure.com/","_blank")}
            >Website</label>
          </div>
        </div>


        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small10} />
              <h6>Signals</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A social-trading network that empowers traders to sell alerts, education and launch automated funds.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Development</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
              onClick={()=>window.open("https://signals.app/","_blank")}
            >Website</label>
          </div>
        </div>
        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small11} />
              <h6>X3.Money</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            The easiest way to buy cryptocurrencies in Canada, Uk and India.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Pre-Launch</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
                  onClick={()=>window.open("https://x3.money","_blank")}
            >Website</label>
          </div>
        </div>


        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small12} />
              <h6>BlocByBloc</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A digital investment platform offering unique strategies which create passive income sources in the crypto market. 
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Pre-Launch</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
               onClick={()=>window.open("https://blocbybloc.world","_blank")}
            >Website</label>
          </div>
        </div>


        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small13} />
              <h6>Bet.Studio</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A a social betting platform for influencers and players to discover and take bets on current events.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Development</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
                   onClick={()=>window.open("https://bet.studio","_blank")}
            >Website</label>
          </div>
        </div>


        
        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small13}
              style={{
                opacity:0
              }}
              
              />
              <h6>Subtl.Ai</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            built neural search technology that can read and understand your private documents at scale
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Development</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
                   onClick={()=>window.open("https://www.subtl.ai/#/Technology","_blank")}
            >Website</label>
          </div>
        </div>


      </div>
    </>
  );
}
