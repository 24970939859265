import React, { useState, useContext } from "react";
import { MainContext } from "../context/newContext";
import './newHomePage.scss';
import mainicon from "../Static/images/mainicon.svg";
import gxicon from "../Static/images/subsidary/gxicon.svg";
import marketverseicon from "../Static/images/subsidary/marketverseicon.svg";
import viralicon from "../Static/images/subsidary/viralicon.svg";
import inricon from "../Static/images/subsidary/inricon.svg";
import employmenticon from "../Static/images/subsidary/employmenticon.svg";
import experiencesicon from "../Static/images/subsidary/experiencesicon.svg";
import cryptostartupsicon from "../Static/images/subsidary/cryptostartupsicon.svg";
import businessesgroupicon from "../Static/images/subsidary/businessesgroupicon.svg";
import spendinggroupicon from "../Static/images/subsidary/spendinggroupicon.svg";
import { useHistory } from "react-router-dom";


const allMenus = [
  {
    id:0,
    text:"Global X Change",
    icon:gxicon,
    link:"/Subsidiaries/gx",
  },
  {
    id:1,
    text:"MarketsVerse",
    icon:marketverseicon,
    link:"/Subsidiaries/MarketsVerse"
  },
  {
    id:2,
    text:"Viral Group",
    icon:viralicon,
    link:"/Subsidiaries/Viral"
  },
  {
    id:3,
    text:"INR Group",
    icon:inricon,
    link:"/Subsidiaries/inr"
  },
  {
    id:4,
    text:"Employment Group",
    icon:employmenticon,
    link:"/Subsidiaries/Employment"
  },

  // {
  //   id:5,
  //   text:"Experiences Group",
  //   icon:experiencesicon,
  //   link:"/Subsidiaries/Experiences"
  // },
  {
    id:5,
    text:"CryptoStartups",
    icon:cryptostartupsicon,
    link:"/Subsidiaries/CryptoStartups"
  },
  {
    id:6,
    text:"Spending Group",
    icon:spendinggroupicon,
    link:"/Subsidiaries/Spending"
  },
  {
    id:7,
    text:"Businesses Group",
    icon:businessesgroupicon,
    link:"/Subsidiaries/businesses"
  },
]


const Subsidiaries = () => {
  let history = useHistory();
  const {
    nav,
    setnav,
    sideNav,
    setSideNav,
    collapsedSideBar,
    navExpand,
    setNavExpand,
    setSubsidaryMenu,
  } = useContext(MainContext);

  return (
    <div>
      <div className='main-logo-box'>
        <img className='main-logo-img' src={mainicon} alt="" />
      </div>
      <div className='menu-items-box'>
        {allMenus.map((each, i) => {
          return(
            <div className='each-sub-item' key={i} onClick={() => history.push(each.link)}>
              <img className='each-sub-item-img' src={each.icon} alt="" />
              <div className='each-sub-item-text'>{each.text}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Subsidiaries;