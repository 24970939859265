import React from 'react'
import './Media.scss'
import Nav from '../components/navbar/navbar'
// media1
// media2
// media3

import media1 from '../Static/media1.svg'
import media2 from '../Static/media2.svg'
import media3 from '../Static/media3.svg'
import media4 from '../Static/media4.svg'
export default function Media() {
  return (
    <>
    {/* <Nav/> */}
    <div className='Mediapage' id="media">
        <h1>Starfish’s Brands In The Media</h1>
        <h2>Our brands are making noise across the globe. Here is a collection of their notable appearences. </h2>
   
   <div>
  <div className='cardsection'>
    <div className='card'
    style={{
      background:`url(${media1})`
    }}
    >
<p>Traditional Press</p>
    </div>
    <div className='card'
       style={{
        background:`url(${media2})`
      }}
    >
<p>Digital Press</p>
    </div>
    <div className='card'
       style={{
        background:`url(${media3})`
      }}
    >
<p>Podcasts</p>
    </div>
    <div className='card'
       style={{
        background:`url(${media4})`
      }}
    >
<p>Live Events</p>
    </div>
    {/* <div className='card'>
<p>Influencers</p>
    </div> */}
   
    </div>
   </div>
    </div>
    </>
  )
}
