import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../context/newContext";
import "./newHomePage.scss";
import clickarrow from "../Static/images/clickarrow.svg";
import { GetAppData } from "../services/appservices";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

////images
import gxfull from "../Static/images/subsidary/gxfull.svg";
import viralfull from "../Static/images/subsidary/viralfull.svg";
import inrfull from "../Static/images/subsidary/inrfull.svg";
import employmentfull from "../Static/images/subsidary/employmentfull.svg";
import experiencefull from "../Static/images/subsidary/experiencefull.svg";
import cryptostartupfull from "../Static/images/subsidary/cryptostartupfull.svg";
import spendingfull from "../Static/images/subsidary/spendingfull.svg";

///gxicons
import mcbicon from "../Static/images/subsidary/mcbicon.svg";
import mvicon from "../Static/images/subsidary/mvicon.svg";
import retiredicon from "../Static/images/subsidary/retiredicon.svg";
import affliateicon from "../Static/images/subsidary/affliateicon.svg";
import instacryptoicon from "../Static/images/subsidary/instacryptoicon.svg";
import cssicon from "../Static/images/subsidary/cssicon.svg";
import tlcicon from "../Static/images/subsidary/tlcicon.svg";
import assetsicon from "../Static/images/subsidary/assetsicon.svg";
import toicon from "../Static/images/subsidary/toicon.svg";
import tsicon from "../Static/images/subsidary/tsicon.svg";
import mmicon from "../Static/images/subsidary/mmicon.svg";
import bondsicon from "../Static/images/subsidary/bondsicon.svg";
import fcicon from "../Static/images/subsidary/fcicon.svg";
import sticon from "../Static/images/subsidary/sticon.svg";
import dricon from "../Static/images/subsidary/dricon.svg";
import ifcicon from "../Static/images/subsidary/ifcicon.svg";
import terminalicon from "../Static/images/subsidary/terminalicon.svg";
import otcicon from "../Static/images/subsidary/otcicon.svg";
import nfticon from "../Static/images/subsidary/nfticon.svg";
import signalsicon from "../Static/images/subsidary/signalsicon.svg";

//marketsverse
import mvfull from "../Static/images/subsidary/mvfull.svg";

///viralicons
import engageicon from "../Static/images/subsidary/engageicon.svg";
import connecicon from "../Static/images/subsidary/connecicon.svg";
import linkedicon from "../Static/images/subsidary/linkedicon.svg";
import coachesicon from "../Static/images/subsidary/coachesicon.svg";
import endorseicon from "../Static/images/subsidary/endorseicon.svg";
import influenceicon from "../Static/images/subsidary/influenceicon.svg";

///inricons
import indianotcicon from "../Static/images/subsidary/indianotcicon.svg";
import tellersicon from "../Static/images/subsidary/tellersicon.svg";
import iiicon from "../Static/images/subsidary/iiicon.svg";
import indiacoinsicon from "../Static/images/subsidary/indiacoinsicon.svg";
import chiticon from "../Static/images/subsidary/chiticon.svg";
import indianbetsicon from "../Static/images/subsidary/indianbetsicon.svg";
import indbondsicon from "../Static/images/subsidary/indbondsicon.svg";
import landlordicon from "../Static/images/subsidary/landlordicon.svg";
import realtoricon from "../Static/images/subsidary/realtoricon.svg";
import web3icon from "../Static/images/subsidary/web3icon.svg";

///employmenticon
import teamforceicon from "../Static/images/subsidary/teamforceicon.svg";
import snapayicon from "../Static/images/subsidary/snapayicon.svg";
import talentmarketicon from "../Static/images/subsidary/talentmarketicon.svg";
import holidaysicon from "../Static/images/subsidary/holidaysicon.svg";
import chatsicon from "../Static/images/subsidary/chats.svg";
import meetingstreamicon from "../Static/images/subsidary/meetingstreamicon.svg";

///experienceicon
import indiannightsicon from "../Static/images/subsidary/indiannightsicon.svg";
import ewicon from "../Static/images/subsidary/ewicon.svg";
import ceicon from "../Static/images/subsidary/ceicon.svg";
import partiesicon from "../Static/images/subsidary/partiesicon.svg";
import promotionicon from "../Static/images/subsidary/promotionicon.svg";
import performanceicon from "../Static/images/subsidary/performanceicon.svg";

///cryptostartupsicons
import cmpicon from "../Static/images/subsidary/cmpicon.svg";
import bsicon from "../Static/images/subsidary/bsicon.svg";
import maicon from "../Static/images/subsidary/maicon.svg";
import csicon from "../Static/images/subsidary/csicon.svg";
import wticon from "../Static/images/subsidary/wticon.svg";
import bcicon from "../Static/images/subsidary/bcicon.svg";
import taxcicon from "../Static/images/subsidary/taxcicon.svg";
import ssicon from "../Static/images/subsidary/ssicon.svg";
import ccricon from "../Static/images/subsidary/ccricon.svg";
import waicon from "../Static/images/subsidary/waicon.svg";
import cryptosicon from "../Static/images/subsidary/cryptosicon.svg";
import emailcicon from "../Static/images/subsidary/emailcicon.svg";

///spendingsicons
import malls from "../Static/images/subsidary/malls.svg";
import reatilers from "../Static/images/subsidary/reatilers.svg";
import spendcrypto from "../Static/images/subsidary/spendcrypto.svg";
import checkout from "../Static/images/subsidary/checkout.svg";

const Subsubsidary = () => {
  const {
    nav,
    setnav,
    sideNav,
    setSideNav,
    collapsedSideBar,
    navExpand,
    setNavExpand,
    subsidaryMenu,
    setSubsidaryMenu,
  } = useContext(MainContext);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const allSubMenus = [
    {
      id: 0,
      text: "Global X Change",
      fullicon: gxfull,
      menu: [
        {
          menuid: 0,
          menutext: "Brands",
          items: [
            {
              itemid: 0,
              name: "Retired App",
              colored_icon: retiredicon,
              description: "Whitelabel Platform For Cryptocurrency Startups",
              website: "https://retired.app/",
            },
            {
              itemid: 1,
              name: "Affiliate App",
              colored_icon: affliateicon,
              description:
                "Social finance ecosystem for users to get paid from their network",
              website: "https://affiliate.app",
            },
            {
              itemid: 2,
              name: "InstaCrypto",
              colored_icon: instacryptoicon,
              description:
                "A global P2P marketplace which connects users with OTCDesks",
              website: "https://instacrypto.com",
            },
            {
              itemid: 3,
              name: "CCSWealth",
              colored_icon: cssicon,
              description:
                "A wealth management platform offering  fixed income bonds.",
              website: "https://ccswealth.com",
            },
            {
              itemid: 4,
              name: "TopLevelCrypto",
              colored_icon: tlcicon,
              description:
                "An asset management platform operating the world’s first IFO.",
              website: "https://toplevelcrypto.com",
            },
            {
              itemid: 5,
              name: "Assets.io",
              colored_icon: assetsicon,
              description:
                "A portfolio management tool providing access to all Marketsverse offerings",
              website: "https://assets.io",
            },
            {
              itemid: 6,
              name: "MyCryptoBrand",
              colored_icon: mcbicon,
              description: "Whitelabel platform for cryptocurrency startups",
              website: "https://mycryptobrand.com/",
            },
            {
              itemid: 7,
              name: "TokenOptions",
              colored_icon: toicon,
              description:
                "A futures trading platform for derivatives of tokenized assets",
              website: "https://tokenoptions.com",
            },
            {
              itemid: 8,
              name: "TokenSwap",
              colored_icon: tsicon,
              description:
                "A non custodial cryptocurrency exchange and liquidity provider.",
              website: "https://tokenswap.io",
            },
          ],
        },
        {
          menuid: 1,
          menutext: "Protocols",
          items: [
            {
              itemid: 0,
              name: "MoneyMarkets",
              colored_icon: mmicon,
              description: "",
              website: "",
            },
            // {
            //     itemid:1,
            //     name:"Bonds",
            //     colored_icon:bondsicon,
            // },
            // {
            //     itemid:2,
            //     name:"FundCoins",
            //     colored_icon:fcicon,
            // },
            // {
            //     itemid:3,
            //     name:"ShareTokens",
            //     colored_icon:sticon,
            // },
            {
              itemid: 1,
              name: "DefiRouting",
              colored_icon: dricon,
              description: "",
              website: "",
            },
            // {
            //     itemid:5,
            //     name:"Index-FundCoins",
            //     colored_icon:ifcicon,
            // },
          ],
        },
        {
          menuid: 2,
          menutext: "MCB Templates",
          items: [
            {
              itemid: 0,
              name: "Terminals",
              colored_icon: terminalicon,
              description: "",
              website: "",
            },
            {
              itemid: 1,
              name: "OTCDesks",
              colored_icon: otcicon,
              description: "",
              website: "",
            },
            {
              itemid: 2,
              name: "NFTReward",
              colored_icon: nfticon,
              description: "",
              website: "",
            },
            {
              itemid: 3,
              name: "Signals",
              colored_icon: signalsicon,
              description: "",
              website: "",
            },
          ],
        },
      ],
    },
    {
      id: 1,
      text: "MarketsVerse",
      fullicon: mvfull,
      menu: [
        {
          menuid: 0,
          menutext: "Protocols",
          items: [...selectedData],
        },
        {
          menuid: 1,
          menutext: "Markets",
          items: [],
        },
      ],
    },
    {
      id: 2,
      text: "Viral Group",
      fullicon: viralfull,
      menu: [
        {
          menuid: 0,
          menutext: "Brands",
          items: [...selectedData],
        },
      ],
    },
    {
      id: 3,
      text: "INR Group",
      fullicon: inrfull,
      menu: [
        {
          menuid: 0,
          menutext: "Direct Holdings",
          items: [...selectedData],
        },
        {
          menuid: 1,
          menutext: "Experiences Group",
          items: [...selectedData],
        },
      ],
    },
    {
      id: 4,
      text: "Employment Group",
      fullicon: employmentfull,
      menu: [
        {
          menuid: 0,
          menutext: "Brands",
          items: [...selectedData],
        },
      ],
    },
    // {
    //   id:5,
    //   text:"Experiences Group",
    //   fullicon:experiencefull,
    //   menu:[
    //     {
    //         menuid:0,
    //         menutext:"Brands",
    //         items:[
    //             {
    //                 itemid:0,
    //                 name:"IndianNights",
    //                 colored_icon:indiannightsicon,
    //                 description:"",
    //                 website:""
    //             },
    //             {
    //                 itemid:1,
    //                 name:"ExperienceWallet",
    //                 colored_icon:ewicon,
    //                 description:"",
    //                 website:""
    //             },
    //             {
    //                 itemid:2,
    //                 name:"CoinEvent",
    //                 colored_icon:ceicon,
    //                 description:"",
    //                 website:""
    //             },
    //             {
    //                 itemid:3,
    //                 name:"Parties",
    //                 colored_icon:partiesicon,
    //                 description:"",
    //                 website:""
    //             },
    //             {
    //                 itemid:4,
    //                 name:"Promotion",
    //                 colored_icon:promotionicon,
    //                 description:"",
    //                 website:""
    //             },
    //             {
    //                 itemid:5,
    //                 name:"Performances",
    //                 colored_icon:performanceicon,
    //                 description:"",
    //                 website:""
    //             },
    //         ]
    //     },

    //   ]
    // },
    {
      id: 5,
      text: "CryptoStartups",
      fullicon: cryptostartupfull,
      menu: [
        {
          menuid: 0,
          menutext: "Brands",
          items: [...selectedData],
        },
      ],
    },
    {
      id: 6,
      text: "Spending Group",
      fullicon: spendingfull,
      menu: [
        {
          menuid: 0,
          menutext: "Brands",
          items: [...selectedData],
        },
      ],
    },
    {
      id: 7,
      text: "Businesses Group",
      fullicon: "",
      menu: [
        {
          menuid: 0,
          menutext: "Brands",
          items: [...selectedData],
        },
      ],
    },
  ];

  useEffect(() => {
    if(sideNav !== 0){
      handleTab(0);
    }
  }, [sideNav]);

  const handleTab = (num) => {
    if(sideNav !== 0){
      setisLoading(true);
    }
    setSubsidaryMenu(num);
    if (sideNav === 1 && num === 0) {
      handleCallApi("marketsgroup");
    } else if (sideNav === 2 && num === 0) {
      handleCallApi("viralgroup");
    } else if (sideNav === 3 && num === 0) {
      handleCallApi("inrgroup");
    } else if (sideNav === 3 && num === 1) {
      handleCallApi("experiencesgroup");
    } else if (sideNav === 4 && num === 0) {
      handleCallApi("employmentgroup");
    } else if (sideNav === 5 && num === 0) {
      handleCallApi("cryptobrandsgroup");
    } else if (sideNav === 6 && num === 0) {
      handleCallApi("spendinggroup");
    } else if (sideNav === 7 && num === 0) {
      handleCallApi("bosgroup");
    }
  };

  const handleCallApi = (parentgroup) => {
    GetAppData(parentgroup).then((response) => {
      if (response.data.status) {
        let result = response.data.brands;
        console.log(result, "kkkk");
        setSelectedData(result);
        setisLoading(false);
      } else {
        setisLoading(false);
        setSelectedData([]);
      }
    });
  };

  const handleOpen = (website) => {
    if (website !== "") {
      window.open(`${website}`, "_blank");
    }
  };

  return (
    <div>
      <div className="full-img-box">
        <img
          className="full-img"
          src={sideNav !== "" ? allSubMenus[sideNav].fullicon : ""}
          alt=""
        />
      </div>
      <div className="all-menus">
        {allSubMenus[sideNav].menu.length > 0 ? (
          <>
            {allSubMenus[sideNav].menu.map((eachMenu, num) => {
              return (
                <div
                  className="each-item-menu"
                  key={num}
                  style={{ background: num === subsidaryMenu ? "#F3F4F5" : "" }}
                  onClick={() => handleTab(num, eachMenu.menutext)}
                >
                  {eachMenu.menutext}
                </div>
              );
            })}
          </>
        ) : (
          ""
        )}
      </div>
      <div className="all-sub-menus">
        {!isLoading && allSubMenus[sideNav].menu.length > 0 ? (
          <>
            {allSubMenus[sideNav].menu[subsidaryMenu]?.items.map((each, i) => {
              return (
                <div className="each-sub-item1" key={i}>
                  <div className="each-sub-item1-box">
                    <img
                      className="each-sub-item-img"
                      src={each.colored_icon}
                      alt=""
                    />
                    <div className="each-sub-item-text">{each.name}</div>
                  </div>
                  <div className="contentBx">
                    <div className="each-sub-item-description">{(each.description === null ||  each.description === undefined)? "" :  each.description}</div>
                    <div
                      className="zoom2"
                      onClick={() => handleOpen(each.website)}
                    >
                      <div
                        className="zoom1"
                        style={{
                          display: each.website !== "" ? "" : "none",
                          cursor:
                            each.website !== "" ? "pointer" : "not-allowed",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            height: "40px",
                          }}
                        >
                          <div className="check1-text">CHECK</div>
                          <img
                            className="clickarrow-img"
                            src={clickarrow}
                            alt=""
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : !isLoading && !allSubMenus[sideNav].menu.length > 0 ? (
          ""
        ) : (
        <div className="all-sub-menus">
          <div className="each-sub-item1">
            <div className="each-sub-item1-box">
                <Skeleton  style={{height:'30px', width:'30px', borderRadius:'50%'}}/>
                <Skeleton  style={{marginLeft:'15px', height:'30px', width:'200px'}}/>
            </div>
          </div>
          <div className="each-sub-item1">
            <div className="each-sub-item1-box">
                <Skeleton  style={{height:'30px', width:'30px', borderRadius:'50%'}}/>
                <Skeleton  style={{marginLeft:'15px', height:'30px', width:'200px'}}/>
            </div>
          </div>
          <div className="each-sub-item1">
            <div className="each-sub-item1-box">
                <Skeleton  style={{height:'30px', width:'30px', borderRadius:'50%'}}/>
                <Skeleton  style={{marginLeft:'15px', height:'30px', width:'200px'}}/>
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default Subsubsidary;
