import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import classNames from "./selectoptions.module.scss";
const allCards = [
  {
    key: 0,
    title: "Partnerships",
    description:
      "Partner with Starfish and scale any business which is catering to startups.",
    link: "/partnerships",
  },
  {
    key: 1,
    title: "Investor Relations",
    description:
      "Want to invest into startups? No problem our Starfish Capital team will help you get started.",
    link: "/investorRelation",
  },
  {
    key: 2,
    title: "Work At Starfish",
    description:
      "Want to work at for one of the Starfish brands? We would love to have you. Apply now!",
    link: "/workatstarfish",
  },

  {
    key: 3,
    title: "Apply For Incubation",
    description:
      "Building the next big thing? We would love to help you bring your vision to reality.",
    link: "/applyforincubation",
  },
  {
    key: 4,
    title: "General Inquiries",
    description:
      "Just curious about Starfish and the many exciting things we are up to? Click here.",
    link: "/generalinquiries",
  },
  {
    key: 5,
    title: "Login Issues",
    description:
      "Contact us immediately if you are already a user of a Starfish app and can’t login.",
    link: "/loginissues",
  },
];

function SelectOptions() {
  let history = useHistory();
  let location = useLocation();

  const handleselect = (each) => {
    if (each.link === "/partnerships" || each.link === "/investorRelation") {
      localStorage.setItem("selectquery", `/contact${each.link}`);
      history.push(`/contact${each.link}`);
    }
  };

  return (
    <div className={classNames.optionsMaster}>
      {/* <div className={classNames.headersection}>
        <p className={classNames.maintitle}>Select Of The Options</p>
        <div className={classNames.maindesc}>
          If you need assistance with an issue regarding your account. You can
          access our dedicated customer support chat inside the application.
          This page is reserved for other types of inquiries.
        </div>
      </div> */}
      <div className={classNames.cardsSection}>
        {allCards.map((each, id) => {
          return (
            <div
              className={
                location.pathname === `/contact${each.link}`
                  ? `${classNames.eachcard} ${classNames.selectactive}`
                  : classNames.eachcard
              }
              key={id}
              onClick={() => handleselect(each)}
            >
              <p className={classNames.queryTitle}>{each.title}</p>
              <p className={classNames.queryDesc}>{each.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SelectOptions;
