import React from "react";
import "./Holiding.scss";
import Navbar from "../components/navbar/navbar";
import logo1 from "../Image/inner7.png";
import upper from "../Image/starrcheck.svg";
import small1 from "../Image/jj1.png";
import small2 from "../Image/jj2.png";
import small3 from "../Image/tsmall3.png";
import small4 from "../Image/tsmall4.png";
import small5 from "../Image/tsmall5.png";
import { useParams, useHistory } from "react-router-dom";

export default function Holiding({setshow,  setselectedtab,
  setmodelopen}) {
  const history = useHistory();
  return (
    <>
      {/* <Navbar /> */}
      <div className="HoldingPage">
      <div className="topheadearhoilder">
              <h1>Our Brands</h1>


              <p
               onClick={()=>
                
                {
                  setselectedtab("hoilding");
                  setmodelopen(true)
                }}>Click Here To See The Valuation Of Our Portfolio In Live Time</p>
              {/* <div className="porprotfoilo"
             
                
              >See Our Portfolio</div> */}
              </div>

        <div className="titleMain"
         onClick={()=>setshow("default")}
        >
          <div className="inner">
            {/* <img src={logo1} /> */}
            <h2>Offline</h2>
          </div>
          <img  className="rotatetranfrom" src={upper} />
        </div>

        <div className="title">
          <div className="tileName">Company</div>
          <div className="tileName">Overview</div>
          <div className="tileName">Status</div>
          <div className="tileName">Position</div>
          <div className="tileName">Website</div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img
                src={small1}
                style={{
                  height: "35px",
                }}
              />
              <h6>Spacecraft</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A luxury interior design firm based in Hyderabad which provides end-to-end servicing for residential properties.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Majority Shareholder</h5>
          </div>
          <div className="contentItem">
            <label
                onClick={()=>window.open("https://spacecraft.biz","_blank")}
            >Website</label>
          </div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small2} />
              <h6>Gravitichain</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            Helping startups with go-to-market strategies, sales acceleration, quality lead generation, and brand building.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>Development</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Wholly Owned </h5>
          </div>
          <div className="contentItem">
            <label
            
            onClick={()=>window.open("https://gravitichain.com","_blank")}
            >Website</label>
          </div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small3}
              style={{
                opacity:0
              }}
              />
              <h6>Speldios</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            India's premier Italian cuisine restaurant chain with locations in Bangalore and Chennai. 
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
              onClick={()=>window.open("https://viamilano.in/","_blank")}
           >Website</label>
          </div>
        </div>

        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small3}
              style={{
                opacity:0
              }}
              />
              <h6>World Of Brands</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            A disruptive technology platform that brings consumers closer to AlcoBev brands.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
              onClick={()=>window.open("https://www.wobstory.com/","_blank")}
           >Website</label>
          </div>
        </div>



        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small3}
              style={{
                opacity:0
              }}
              />
              <h6>Spriggen Gardener</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            Pioneering the organized garden business in India with services and plant products having operations.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
              onClick={()=>window.open("https://greenbrigade.co/","_blank")}
           >Website</label>
          </div>
        </div>


        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small3}
              style={{
                opacity:0
              }}
              />
              <h6>STEM Education</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            Implementation & delivery partners from preschool to K12 inculding solutions for learning disabilities.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
              onClick={()=>window.open("http://academia.ventureshttp://lillighthouse.co/","_blank")}
           >Website</label>
          </div>
        </div>


        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small3}
              style={{
                opacity:0
              }}
              />
              <h6>Future Ink Media</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            Future Ink Media is a 360 Degree Digital Marketing Agency
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
              onClick={()=>window.open("http://www.futureinkmedia.com/aboutus.html","_blank")}
           >Website</label>
          </div>
        </div>



        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small3}
              style={{
                opacity:0
              }}
              />
              <h6>Makers Hive</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            Created a fully functioning and mobile app-enabled Bionic Hand-KalArm  to transform persons with amputations.
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
              onClick={()=>window.open("https://makershive.io/","_blank")}
           >Website</label>
          </div>
        </div>


        
        <div className="sectoinshowtoggle">
          <div className="contentItem">
            <div className="inner">
              <img src={small3}
              style={{
                opacity:0
              }}
              />
              <h6>LK Labs</h6>
            </div>
          </div>
          <div className="contentItem">
            <p>
            Creating transportation and product solutions that would contribute towards building a better life for all Indians
            </p>
          </div>
          <div className="contentItem">
          <div className="status">Status</div>
            <h5>In Market</h5>
          </div>
          <div className="contentItem">
          <div className="status">Position</div>
            <h5>Minority Stake</h5>
          </div>
          <div className="contentItem">
            <label
              onClick={()=>window.open("https://makershive.io/","_blank")}
           >Website</label>
          </div>
        </div>
      </div>
    </>
  );
}
