import React from "react";
import styles from "./tos.module.scss";
import Navbar from "../../components/navbar/navbar";

const Tos = () => {
  return (
    <>
      <Navbar />
      <div className={styles.container}>
        <h1>
          <strong>Terms of Service</strong>
        </h1>
        <p>
          These Terms of Service ("Terms") govern your use of the services
          provided by Starfish Accelerator Partners Private Limited ("Starfish
          Accelerator"). By accessing or using our services, you agree to be
          bound by these Terms.
        </p>

        <h2>
          <strong>1. Services</strong>
        </h2>
        <p>
          Starfish Accelerator provides various services as agreed upon in your
          service agreement or contract. These services may include but are not
          limited to:
        </p>
        <ul>
          <li>Business consulting</li>
          <li>Investment advisory</li>
          <li>Mentorship and guidance</li>
          <li>Networking opportunities</li>
        </ul>

        <h2>
          <strong>2. Client Obligations</strong>
        </h2>
        <p>By using our services, you agree to:</p>
        <ul>
          <li>
            Provide accurate and complete information necessary for the
            provision of our services.
          </li>
          <li>
            Comply with any guidelines, instructions, or requirements provided
            by Starfish Accelerator.
          </li>
          <li>
            Use our services for lawful purposes and not engage in any illegal
            or unauthorized activities.
          </li>
          <li>
            Respect the confidentiality of any proprietary or confidential
            information provided by Starfish Accelerator.
          </li>
          <li>
            Pay the agreed-upon fees and charges for the services as outlined in
            your service agreement or contract.
          </li>
        </ul>

        <h2>
          <strong>3. Intellectual Property</strong>
        </h2>
        <p>
          All intellectual property rights, including but not limited to
          copyrights, trademarks, and trade secrets, associated with our
          services and any materials provided by Starfish Accelerator remain the
          exclusive property of Starfish Accelerator.
        </p>
        <p>
          You are prohibited from reproducing, distributing, modifying, or
          creating derivative works of any content or materials provided by
          Starfish Accelerator without prior written permission.
        </p>

        <h2>
          <strong>4. Limitation of Liability</strong>
        </h2>
        <p>
          Starfish Accelerator shall not be liable for any direct, indirect,
          incidental, special, consequential, or exemplary damages resulting
          from the use or inability to use our services or any actions or
          conduct of any third party.
        </p>
        <p>
          Starfish Accelerator makes no warranties or representations, express
          or implied, regarding the accuracy, reliability, or suitability of the
          services provided.
        </p>

        <h2>
          <strong>5. Termination</strong>
        </h2>
        <p>
          Either party may terminate the provision of services by providing
          written notice to the other party. Any outstanding fees or obligations
          shall be settled according to the terms specified in your service
          agreement or contract.
        </p>

        <h2>
          <strong>6. Governing Law</strong>
        </h2>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of [Jurisdiction]. Any legal actions or proceedings arising out
          of these Terms shall be brought exclusively in the courts of
          [Jurisdiction].
        </p>

        <h2>
          <strong>7. Changes to the Terms</strong>
        </h2>
        <p>
          Starfish Accelerator reserves the right to modify or update these
          Terms of Service at any time. Any changes will be effective
          immediately upon posting the revised Terms on our website or notifying
          you directly. It is your responsibility to review the Terms
          periodically to stay informed about any updates.
        </p>

        <p>
          If you have any questions or concerns regarding these Terms of
          Service, please contact us through our official communication
          channels.
        </p>

        <p>Thank you for using Starfish Accelerator's services.</p>

        <p>Starfish Accelerator Partners Private Limited</p>
      </div>
    </>
  );
};

export default Tos;
