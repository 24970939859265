import { useCallback, useEffect, useRef, useState } from "react";
import classNames from "./contactus.module.scss";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
// import MainLayout from "../../layouts/MainLayout";
// import { each } from "immer/dist/internal";

const allCards = [
  {
    key: 0,
    title: "Partnerships",
    description:
      "Partner with Starfish and scale any business which is catering to startups.",
    link: "/partnerships",
  },
  {
    key: 1,
    title: "Investor Relations",
    description:
      "Want to invest into startups? No problem our Starfish Capital team will help you get started.",
    link: "/investorRelation",
  },
  {
    key: 2,
    title: "Work At Starfish",
    description:
      "Want to work at for one of the Starfish brands? We would love to have you. Apply now!",
    link: "/workatstarfish",
  },

  {
    key: 3,
    title: "Apply For Incubation",
    description:
      "Building the next big thing? We would love to help you bring your vision to reality.",
    link: "/applyforincubation",
  },
  {
    key: 4,
    title: "General Inquiries",
    description:
      "Just curious about Starfish and the many exciting things we are up to? Click here.",
    link: "/generalinquiries",
  },
  {
    key: 5,
    title: "Login Issues",
    description:
      "Contact us immediately if you are already a user of a Starfish app and can’t login.",
    link: "/loginissues",
  },
];

const ContactUs = () => {
  let history = useHistory();
  const handleselect = (each) => {
    if (each.link === "/partnerships" || each.link === "/investorRelation") {
      history.push(`/contact${each.link}`);
    }
  };

  return (
    <>
      {/* <Navbar /> */}
      <div style={{ paddingTop: "0px", height: "100vh" }} id="contact">
        <div className={classNames.contactMaster}  id="contact">
          <div className={classNames.titlesection}>
            <p className={classNames.mainTitle}>
              {window.innerWidth > 600
                ? "Welcome To Starfish Support"
                : "Starfish Support"}
            </p>
            <div className={classNames.mainDesc}>
              If you are already a user of one of our apps, please use the
              dedicated customer support chat after you login.
            </div>
          </div>
          <div className={classNames.querysection}>
            {allCards.map((each, id) => {
              return (
                <div
                  className={classNames.eachcard}
                  key={id}
                  onClick={() => handleselect(each)}
                >
                  <p className={classNames.queryTitle}>{each.title}</p>
                  <p className={classNames.queryDesc}>{each.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
