import {createContext, useState } from 'react';

export const MainContext = createContext();

const MainContextProvider = ({ children }) => {
    const[nav, setnav] = useState("About");
    const[index, setIndex] = useState(0);
    const[sideNav, setSideNav] = useState(0);
    const[collapsedSideBar, setCollapsedSideBar] = useState(false);
    const[navExpand, setNavExpand] = useState(false);
    const[subsidaryMenu, setSubsidaryMenu] = useState(0);
    const[mobileScreensNav, setMobileScreensNav] = useState("About");
    const [showEach, setShowEach] = useState("");
    const[gotoTop, setgotoTop] = useState(false);

    
    const value = {
        index, setIndex,
        nav, setnav,
        sideNav, setSideNav,
        collapsedSideBar, setCollapsedSideBar,
        navExpand, setNavExpand,
        subsidaryMenu,setSubsidaryMenu,
        mobileScreensNav, setMobileScreensNav,
        showEach, setShowEach,
        gotoTop, setgotoTop
    }
    return (
        <MainContext.Provider value={value}>
            {children}
        </MainContext.Provider>
    );
}

export default MainContextProvider;

export const MainContextConsumer = MainContext.Consumer