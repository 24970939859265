import axios from 'axios';

///// app data (Get all apps under parent group)

export const GetAppData = (parentgroup) => {
    try{
      const response = axios.get(`https://comms.globalxchange.io/gxb/app/gxlive/operator/brands/get?parent_group_id=${parentgroup}`);
      return response;
    }catch(error){
      return error.response
    }
  }